import styled from "styled-components";

const typeEnum = {
  "capsule": "capsule",
  "box": "box",
  "text-s": "text-s",
  "text-l": "text-l",
};

export const variantEnum = {
  "brand1": "brand1",
  "brand1-subtle": "brand1-subtle",
  "brand2": "brand2",
  "brand2-subtle": "brand2-subtle",
  "beige": "beige",
  "beige-subtle": "beige-subtle",
  "mute": "mute",
};

export const ButtonStyle = styled.button`  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: auto;
  flex-shrink:0; 
  outline: none;
  border:none;
  user-select:none;

  gap: ${(props) => props.theme.spacing["spacing-04"]};
  ${(props) => props.theme.typography[props.typography||"pre-body-03"]};

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:focus,
  &:hover{
    background: ${(props)=>props.theme.colors["button-mute-hover"]};
  }

  ${(props) => matchButtonType(props.$buttonType, props.theme)};
  ${(props) => matchButtonVariant(props.$variant, props.theme, props.$buttonType,)};
`;

const matchButtonType = ($typeEnum, theme) => {
  switch ($typeEnum) {
    case typeEnum["text-l"]:
      return {
        height: "40px",
        padding: `${theme.spacing["spacing-none"]} ${theme.spacing["spacing-02"]}`,
        borderRadius: `${theme.radius["radius-01"]}`,
        gap: `${theme.spacing["spacing-02"]}`,
        border: "none",
      };
    case typeEnum["text-s"]:
      return {
        height: "32px",
        padding: `${theme.spacing["spacing-none"]} ${theme.spacing["spacing-02"]}`,
        borderRadius: `${theme.radius["radius-01"]}`,
        gap: `${theme.spacing["spacing-02"]}`,
        border: "none",
      };
    case typeEnum["capsule"]:
      return {
        height: "40px",
        padding: `${theme.spacing["spacing-none"]} ${theme.spacing["spacing-08"]}`,
        borderRadius: `${theme.radius["radius-full"]}`,
        gap: `${theme.radius["spacing-04"]}`,
      };
    case typeEnum["box"]:
      return {
        height: "32px",
        padding: `${theme.spacing["spacing-none"]} ${theme.spacing["spacing-05"]}`,
        borderRadius: `${theme.radius["radius-01"]}`,
        gap: `${theme.spacing["spacing-02"]}`,
        border: "none",
      };
    default:
      return {
        height: "32px",
        padding: `${theme.spacing["spacing-none"]} ${theme.spacing["spacing-05"]}`,
        borderRadius: `${theme.radius["radius-01"]}`,
        gap: `${theme.spacing["spacing-02"]}`,
      };
  }
};

const matchButtonVariant = ($variant, theme, $buttonType) => {
  // const opacity = $buttonType?.includes("text")? "00":"FF"
  const opacity = "FF"
  switch ($variant) {
    case variantEnum["brand1"]:
      return {
        ...variantStyle(
          {
            background: `${theme.colors["button-brand1"]}${opacity}`,
            color: `${theme.colors["grey-white"]}`,
            border: "none",
          },
          "button-brand1-hover",
          "button-brand1-active",
          theme
        ),
      };
    case variantEnum["brand1-subtle"]:
      return {
        ...variantStyle(
          {
            background: `${theme.colors["grey-white"]}${opacity}`,
            border: "1.5px solid",
            borderColor: `${theme.colors["border-brand"]}`,
            color: `${theme.colors["text-brand1"]}`,
          },
          "button-brand1-subtle-hover",
          "button-brand1-subtle-active",
          theme
        ),
      };
    case variantEnum["brand2"]:
      return {
        ...variantStyle(
          {
            background: `${theme.colors["button-brand2"]}${opacity}`,
            border: "none",
            color: `${theme.colors["text-primary-inverse"]}`,
          },
          "button-brand1-hover",
          "button-brand1-active",
          theme
        ),
      };
    case variantEnum["brand2-subtle"]:
      return {
        ...variantStyle(
          {
            background: `${theme.colors["button-brand2-subtle"]}${opacity}`,
            border: "none",
            color: `${theme.colors["text-brand1"]}`,
          },
          "button-brand2-subtle-hover",
          "button-brand2-subtle-active",
          theme
        ),
      };
    case variantEnum["beige"]:
    case variantEnum["beige-subtle"]:
      return {
        ...variantStyle(
          {
            background: `none`,
            border: "none",
            color: `${theme.colors["text-brand2"]}`,
          },
          "button-mute-hover",
          "button-mute-active",
          theme
        ),
      };  
    default:
      return {
        ...variantStyle(
          {
            background: `${theme.colors["grey-white"]}${opacity}`,
            color: `${theme.colors["text-brand2"]}`,
          },
          "button-mute-hover",
          "button-mute-active",
          theme
        ),
      };
  }
};

const variantStyle = (defaultStyles, hoverColor, activeColor, theme) => {
  return {
    ...variantDefaultStyle(defaultStyles),
    "&:hover": {
      background: `${theme.colors[hoverColor]}`,
    },
    "&:focus": {
      background: `${theme.colors[hoverColor]}`,
    },
    "&:active": {
      background: `${theme.colors[activeColor]}`,
    },
  };
};

const variantDefaultStyle = (styles) => {
  return {
    ...styles,
    "&:disabled,&:disabled:hover,&:disabled:active": {
      ...styles,
    },
  };
};
