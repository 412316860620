import { WebInitHibernation } from "../Api/ai";
import screenEffectJson from "../Data/screenEffect.json"

export const getMediaDimension = async (file) => {
  // 파일 타입, 사이즈 반환하는 함수
  try{
    let fileType = 0;
    const type = file.type.split("/")[0];
    switch(type){
      case "image":
        fileType = 0;
        break;
      case "video":
        fileType = 1;
        break;
    }

    const url = await URL.createObjectURL(file);
    const promise = await new Promise(async(resolve, reject)=>{
      if(fileType===1){
        // 동영상인 경우
        try{  
          const videoEl = await document.createElement("video");
          videoEl.src= url;          
          videoEl.onloadedmetadata = () => {
            const width = videoEl.videoWidth;
            const height = videoEl.videoHeight;
            resolve ({result:0, errMsg:"", info:{type: fileType, width: width, height: height}})
          }
        }catch(err){
          // console.error("get video dimension error ::: ", err);
          reject(`get video dimension error ::: ${err}`)
        }
      }else{
        // 이미지인 경우
        try{
          const imageEl = await document.createElement("img");
          imageEl.src = url;
          imageEl.onload = () => {
            const width = imageEl.naturalWidth;
            const height = imageEl.naturalHeight;
            resolve ({result:0, errMsg:"", info:{type: fileType, width: width, height: height}})
          }
                      
        }catch(err){
          // console.error(`get image dimension error ::: ${err}`);
          reject(`get image dimension error ::: ${err}`);
        }
      }
    })
    return promise;
  }catch(err){
    console.error(err);
    return {result:-1, errMsg:err, info:undefined}
  }    
}

export function matchEffect(effectName:string){
  
  const idx = screenEffectJson.findIndex(effect=>effect.effect === effectName);
  if(idx>=0){
    return screenEffectJson[idx].name
  }else{
    const nameIdx = screenEffectJson.findIndex(effect=>effect.name === effectName);
    if(nameIdx>=0){
      return screenEffectJson[nameIdx].name
    }else{
      return "none"
    }
  }
}

function formatting(value){
  return ('00'+value.toString()).slice(-2);      
}

export const getTimeString = () => {
  const currentTime = new Date();

  const YY = formatting(currentTime.getFullYear());
  const MM = formatting(currentTime.getMonth()+1);
  const DD = formatting(currentTime.getDate());
  const hh = formatting(currentTime.getHours());
  const mm = formatting(currentTime.getMinutes());
  const ss = formatting(currentTime.getSeconds());

  return `${YY}${MM}${DD}_${hh}${mm}${ss}`
}

export async function callHibernation(){
  const response = await WebInitHibernation();
  if(response.result!==0){
    console.error("Failed to init hibernation")
  }
}

export const ENABLE_I2V = process.env.REACT_APP_SERVER_TYPE==="DEV"