import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState, memo } from "react";

import musicWave from "../../../../../Assets/icon-editor/timeline-music-wave.png";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { WebGetFile } from "../../../../../Api/resource";

import { AudioVisualizer } from "react-audio-visualize";
import { EditorPageContext } from "../../../editorPageContext";
// import { MasherContext } from "../../../../../Components/Masher";

interface ItemProps {
  title: string;
  duration: number;
  stepSize: number;
  onClick?: Function;
  blob?:Blob;
  selected?:boolean;
  clip?:any;
}

const MusicItem = ({ title="", duration=5, stepSize=40, onClick=undefined, blob=undefined,selected=false, clip=undefined }: ItemProps): JSX.Element => {
  // const [txt, setTxt] = useState(text);
  // const {editor} = useContext(MasherContext)
  const [end, setEnd] = useState(duration * stepSize * 10);
  const [isSelected, setIsSelected] = useState(false);
  const [musicTitle, setMusicTitle] = useState("");
  const [musicBlob, setMusicBlob] = useState(undefined)
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext)

  // useEffect(()=>{
  //   if(clip){
  //     const musicIndex = parseInt(clip?.label.split("_idx:")[1]);
  //     const musicTitle = scenarioContext.BGMInfo[musicIndex].musicTitle;
  //     console.log("bgm changed to ", scenarioContext.BGMInfo[musicIndex]);
  //     getMusicBlob(scenarioContext.BGMInfo[musicIndex].path)
  //     setMusicTitle(musicTitle)
  //   }
  // },[clip])

  async function getMusicBlob(path){
    const musicRequest = {
      assetId:sessionStorage.getItem("ASSET_ID"),
      bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
      key: path
    }
    const fileResponse = await WebGetFile(musicRequest);
    
    if(!fileResponse.result){
      setMusicBlob(fileResponse);
    }
  }

  const visualizerRef = useRef(null)

  const handleClick = (e) => {
    // setIsSelected(prev=>!isSelected);

    editorPageContext.setSelectedItem({
      type:'bgm',
      title:musicTitle,
      // clip:clip
    })

    if(onClick){
      onClick();
    }
  }
  useEffect(()=>{
    setIsSelected(selected);
  },[selected])

  return (
    <Box display="flex" width={duration * stepSize * 10} height={32} justifyContent="space-between" alignItems="center" pr={2} pl={2} py={0} 
      bgcolor={isSelected? "var(--color-support-musicbar-active, #00C59C)" : "#85DECC"}
      position="relative" borderRadius={1} overflow="hidden" onClick={handleClick}>
      <Box position="absolute" width="100%" height="100%" top={0} left={0} bgcolor={"#84decb"} sx={{ mixBlendMode: "multiply" }} >
        {/* <img style={{ width:"100%", height: 32 }} alt="" src={musicWave} /> */}
        <AudioVisualizer
          ref={visualizerRef}
          blob={blob}
          width={duration * stepSize * 10}
          height={32}
          barWidth={1}
          gap={0}
          barColor={'#57AE9E'}
        />
      </Box>  
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, left: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
        <Typography position="relative" flex={1} fontWeight="bold" color="white" fontSize="0.875rem" letterSpacing="0.1em" lineHeight="1.5"> {title} </Typography>
      </>
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, right: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
      </>
    </Box>
  )
}

interface Props {
  stepSize: number,
  onClickItem?: Function,
  clips?: any[]
}

export const MusicTrack = ({ stepSize, onClickItem=undefined, clips=[] }: Props): JSX.Element => {
  // const [posX, setPosX] = useState(0);
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext)

  const [bgmInfo, setBgmInfo] = useState(undefined);

  const [musicBlob, setMusicBlob] = useState(undefined);

  const visualizerRef = useRef(null);

  useEffect(()=>{
    setMusicBlob(editorPageContext.bgmBlobs[0])
  },[editorPageContext.bgmBlobs])
  
  useEffect(()=>{
    const bgm = scenarioContext.BGMInfo[scenarioContext.selectedBgmIndex];
    if(bgm){
      setBgmInfo(bgm);
      getMusic(scenarioContext.selectedBgmIndex);
    }
  },[scenarioContext.selectedBgmIndex, editorPageContext.bgmBlobs, scenarioContext.BGMInfo])

  async function getMusic(index){
    // const musicRequest = {
    //   assetId:sessionStorage.getItem("ASSET_ID"),
    //   bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
    //   key: bgm.path
    // }
    // const fileResponse = await WebGetFile(musicRequest);
    
    // if(!fileResponse.result){
    //   console.log("get music file")
    //   setMusicBlob(fileResponse);
    // }
    setMusicBlob(editorPageContext.bgmBlobs[index])
  }

  const handleOnClickItem = async () => {
    if(onClickItem){
      if(editorPageContext.selectType==="bgm"){
        onClickItem({type:undefined, index:undefined})
      }else{
        onClickItem({type:"bgm", index:0})
      }
    }
  }

  return (
    <Stack gap={0} direction="row" sx={{ height: "32px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
      {bgmInfo &&
        <MusicItem selected={(editorPageContext.selectType==="bgm")&&(editorPageContext.selectIndex===0)} onClick={handleOnClickItem} blob={musicBlob} title={bgmInfo?.musicTitle} duration={parseFloat(bgmInfo?.duration)} stepSize={stepSize} />
      }
    </Stack>
  )
}

export const MemoizedMusicTrack = memo(MusicTrack)