import styled from "styled-components";
import {ReactComponent as CheckIcon} from "../../../Assets/Icons/Check.svg"

export const CheckButtonStyle = styled.button`
  align-items: center;
  background-color: ${(props)=>props.theme.colors["button-mute"]};
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  display: inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  height: 48px;
  box-sizing: border-box;
  overflow: hidden;
  outline:none;
  border: none;
  
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]}`
  }};

  ${(props)=>props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-brand2"]};

  cursor:pointer;

  &:focus,
  &:hover{
    background-color: ${(props)=>props.theme.colors["button-mute-hover"]};
  }

  &.checked{
    border: ${(props)=>props.theme.border["border-03"]} solid;
    border-color: ${(props)=>props.theme.colors["border-active"]};
    ${(props)=>props.theme.typography["pre-body-03"]};
    color:${(props)=>props.theme.colors["text-brand1"]};
    padding-top: calc(${(props)=>props.theme.spacing["spacing-none"]} - ${(props)=>props.theme.border["border-03"]});
    padding-right: calc(${(props)=>props.theme.spacing["spacing-07"]} - ${(props)=>props.theme.border["border-03"]});
    padding-bottom: calc(${(props)=>props.theme.spacing["spacing-none"]} - ${(props)=>props.theme.border["border-03"]});
    padding-left: calc(${(props)=>props.theme.spacing["spacing-07"]} - ${(props)=>props.theme.border["border-03"]});
  }  
`

export const CheckButtonIconStyle = styled.div`
  align-items:center;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  display: inline-flex;
  flex: 0 0 auto;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  padding: ${(props)=>props.theme.spacing["spacing-01"]};
  position:relative;
  width: 24px;
  height: 24px;
`

export const CheckButtonTextStyle = styled.div`
  position:relative;
  white-space: nowrap;
  width: fit-content;
`

export const CheckIconStyle  =styled(CheckIcon)`
  width: 16px;
  height: 16px;
  position:relative;
  stroke: ${(props)=>props.theme.colors["border-active"]};
  stroke-width: 3px;
`