import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PlayerIcon from "./PlayerIcon";
import PlayerTime from "./PlayerTime";
import PlayerSeeker from "./PlayerSeeker";
import PlayerSoundBar from "./PlayerSoundBar";

import { Slider } from "@mui/material";

function PlayerController(props){
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(100)
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClickMute = ()=> {
    setMuted(!muted);
    if(props.onClickMute){
      props.onClickMute();
    }
  }

  const handleClickPlay = () => {
    // setIsPlaying(!isPlaying)
    if(props.onClickPlay){
      props.onClickPlay();
    }
  }

  const handleOnClickBack = () => {
    if(props.onClickBack){
      props.onClickBack()
    }
  }
  const handleOnClickForward = () => {
    if(props.onClickForward){
      props.onClickForward()
    }
  }

  return(
    <ControllerStyle {...props} ref={undefined} onClick={undefined} >
      <ControllerFrame>
        <ButtonWrapper>
          {props.isPlaying? <PlayerIconStyle onClick={handleClickPlay} icon="pause"/> : <PlayerIconStyle onClick={handleClickPlay} icon="play"/>}
          <PlayerIconStyle onClick={handleOnClickBack} icon="back"/>
          <PlayerIconStyle onClick={handleOnClickForward} icon="forward"/>
        </ButtonWrapper>
        <VolumeWrapper>
          {/* <Slider min={0} max={100} sx={{width:"120px"}} color={"secondary"}/> */}
          <PlayerSoundBar value={volume}/>
          {muted? <PlayerIconStyle onClick={handleClickMute} icon="mute"/>:<PlayerIconStyle onClick={handleClickMute} icon="volume"/>}          
        </VolumeWrapper>
      </ControllerFrame>
      <ControllerFrame>
        <PlayerSeeker
          current={props.current}
          duration={props.duration}
          onDragHandle={props.onDragHandle}/>
        <PlayerTime current={props.current} duration={props.duration}/>
      </ControllerFrame>
    </ControllerStyle>
  )
}

export default PlayerController

const PlayerIconStyle = styled(PlayerIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

const ControllerStyle = styled.div`
  align-items:center;
  background-color: ${(props)=>props.theme.colors["background-overlay-subtle"]};
  display:flex;
  flex-direction: column;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-06"]} ${spacing["spacing-09"]} ${spacing["spacing-06"]} ${spacing["spacing-09"]}`
  }};

  width:100%;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
`

const ControllerFrame = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 ;
  justify-content: space-between;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width: 100%;
  height: 24px;
`
const ButtonWrapper = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  display:inline-flex;
  width: fit-content;
`
const VolumeWrapper = styled.div`
  display: inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  align-items:center;
`
