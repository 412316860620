import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Time15 } from "../../../Assets/Illustrate/time15.svg";
import { ReactComponent as Time30 } from "../../../Assets/Illustrate/time30.svg";

import Lottie from 'lottie-react';

import animation15 from "../../../animation/15sec.json"
import animation30 from "../../../animation/30sec.json"

import { ButtonQuestionStyle, ButtonQuestionContentsStyle, ButtonQuestionTitleStyle, ButtonQuestionDescStyle, ButtonIconWrapper } from "./ButtonQuestion.styled";

function ButtonQuestion(props){
  const buttonRef = useRef(null);
  
  const lottieRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false)
  
  useEffect(()=>{
    console.log(`selected changed to ${props.selected} at ${props.value}`)
    const element = buttonRef.current as HTMLElement
    if(element){
      if(props.selected){
        if(!element.classList.contains("selected")){
          element.classList.add("selected")
        }
      }else{
        element.classList.remove("selected")
      }
    }
  },[props.selected])
  
  const handleOnClick = (e)=>{
    // console.log(e.currentTarget)
    if(props.onClick){
      props.onClick();
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
    const el = buttonRef.current as HTMLButtonElement;
    if(el){
      el.blur();
    }
  }

  useEffect(()=>{
    if(lottieRef.current){
      if(isHovered){
        lottieRef.current.goToAndPlay(0,true);
        setIsHovered(false);
      }
    }
  },[isHovered])

  return (
    <ButtonQuestionStyle ref={buttonRef} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {props.value &&
        <ButtonIconWrapper>
          {/* {props.value===15? <Time15/>:<Time30/>} */}
          {props.value===15?
            <Lottie
              lottieRef={lottieRef}
              animationData={animation15}
              loop={false}
            />
            :
            <Lottie
              lottieRef={lottieRef}
              animationData={animation30}
              loop={false}
            />
          }

        </ButtonIconWrapper>
      }
      <ButtonQuestionContentsStyle>
        <ButtonQuestionTitleStyle>{props.text}</ButtonQuestionTitleStyle>
        {props.showInfoText && <ButtonQuestionDescStyle>{props.infoText}</ButtonQuestionDescStyle>}        
      </ButtonQuestionContentsStyle>
    </ButtonQuestionStyle>
  )
}

export default ButtonQuestion
