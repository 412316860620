import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { ImageListItem } from "../../../../../Components/pages/Editor/ImageListItem";
import { IconListItem } from "../../../../../Components/pages/Editor/IconListItem";
import { MusicListItem } from "../../../../../Components/pages/Editor/MusicListItem";

import { ReactComponent as AIEraserIcon } from '../../../../../Assets/icon-editor/image-ai-eraser.svg';
import { ReactComponent as AIBackgroundIcon } from '../../../../../Assets/icon-editor/image-ai-background.svg';

import { ReactComponent as TransRightIcon } from '../../../../../Assets/icon-editor/transition-right.svg';
import { ReactComponent as TransLeftIcon } from '../../../../../Assets/icon-editor/transition-left.svg';
import { ReactComponent as TransUpIcon } from '../../../../../Assets/icon-editor/transition-up.svg';
import { ReactComponent as TransDownIcon } from '../../../../../Assets/icon-editor/transition-down.svg';
import { ReactComponent as TransZoomOutIcon } from '../../../../../Assets/icon-editor/transition-zoom-out.svg';
import { ReactComponent as TransCircleIcon } from '../../../../../Assets/icon-editor/transition-circle.svg';
import { ReactComponent as TransWaveIcon } from '../../../../../Assets/icon-editor/transition-wave.svg';
import { ReactComponent as TransMosaicIcon } from '../../../../../Assets/icon-editor/transition-mosaic.svg';
import { fileInfo, ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";

import { WebResourceUpload, WebPostPreviewScene, WebPostPreviewText, WebResourceDelete, WebGetUrl, WebGetFile } from "../../../../../Api/resource";
import AlertModal from "../../../../../Components/common/AlertModal";
import { ReactComponent as QuestionIcon } from "../../../../../Assets/ModalIllu/Question.svg";

import textEffectData from "../../../../../Data/textEffect.json"
import transitionEffectData from "../../../../../Data/screenEffect.json"
import fontData from "../../../../../Data/fontData.json"

import { AudioVisualizer } from 'react-audio-visualize';
// import { MasherContext } from "../../../../../Components/Masher";
import { fileTypeFromBlob } from 'file-type';
import { EditorPageContext } from "../../../editorPageContext";
// import { AudioClass, ClipClass, clipDefaults, DefaultContentId } from "@moviemasher/moviemasher.js";

import { EditorContext } from "../../../../../Contexts/EditorContext";
import LoadingScreen from "../../../../../Components/common/LoadingScreen";

import screenEffectIconData from "../../../../../Data/screenEffectIconData.json"

import { useTranslation } from "react-i18next";
import i18n from "../../../../../locales/i18n";
import { getMediaDimension, matchEffect } from "../../../../../Utilities";
import { WebGetSupernova, WebPostSupernovaImage, WebPostSupernovaVideo } from "../../../../../Api/ai";
import { isLogging } from "../../../../../App";
import { PanelContext } from "../../PanelContext";
import { resolve } from "path";
import {ReactComponent as AlertIcon} from "../../../../../Assets/ModalIllu/check.svg"


const StyledTabContainer = styled.div`
  position: relative;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap:wrap;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  padding: var(--tokens-spacing-07-duplicate) 0px;
  overflow: scroll;
  box-sizing: border-box;

  &::-webkit-scrollbar{
    display: none;
  }
`;

export const TabContainer = (props): JSX.Element => {
  const { t } = useTranslation();
  const shopInfoContext = useContext(ShopInfoContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const editorPageContext = useContext(EditorPageContext);

  const validExtensions = ["jpg", "jpeg", "png", "mp4"]; // 디폴트는 모든 확장자 허용.

  const [alertDescription, setAlertDescription] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = ()=>{setShowAlert(false)};
  const [alertIcon,setAlertIcon] = useState(<QuestionIcon/>);

  const {isLoading, setIsLoading} = editorPageContext;
  const {filter} = useContext(PanelContext)

  // const [imageUrlList, setImageUrlList] = useState([]);

  // useEffect(()=>{
  //   let newList=[];
  //   shopInfoContext.editedFiles.map(fileInfo=>{
  //     const url = URL.createObjectURL(fileInfo.file)
  //     console.log("image url :: " , url)
  //     const name = fileInfo.file.name;
  //     const type = fileInfo.mainCategory==="image"? "이미지":"비디오";
  //     newList.push({url:url, type:type, name:name})
  //   })
  //   setImageUrlList(newList)

  // },[shopInfoContext.editedFiles])


  async function renderWithNewImage({finalScenario, sceneIndex, screenEffectList}){
    const currentScene = finalScenario.scenes[sceneIndex];
    const nextScene = (finalScenario.scenes.length-1)===sceneIndex? finalScenario.scenes[sceneIndex]:finalScenario.scenes[sceneIndex+1]

    const sceneRequest = {
      assetId: sessionStorage.getItem("ASSET_ID"),
      sceneNo: sceneIndex,
      screenBefore: currentScene.layoutList[0].path,
      screenAfter: nextScene.layoutList[0].path,
      screenBeforeEffect: screenEffectList[sceneIndex].before,
      screenAfterEffect: screenEffectList[sceneIndex].after,
      effect: matchEffect(currentScene.effect),
      duration: currentScene.time,
      durationAfter: nextScene? nextScene.time : 3,
      mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
      serverType: process.env.REACT_APP_SERVER_TYPE,
      inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
      outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
      shopName:shopInfoContext.shopName,
      isDev: false,
      languageCode: shopInfoContext.language,
      filter: filter,
      nextFilter: nextScene.filter
    }

    const fileResponse:any = await WebPostPreviewScene(sceneRequest)

    if(fileResponse.status===200){
      const blob = new Blob([fileResponse.data],{type:"video/mp4"})
      const url = await URL.createObjectURL(blob);
      
      let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
      newList[sceneIndex] = url
      editorPageContext.setSceneVideoUrlList(prev=>newList);
    }else{
      let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
      newList[sceneIndex] = ""
      editorPageContext.setSceneVideoUrlList(prev=>newList);
    }
  }


  const handleAdd = async (fileName:string) => {
    if(editorPageContext.selectType==="video" && editorPageContext.selectIndex!==undefined){
      setIsLoading(prev=>true);
      const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name===fileName);
      const fileKey = shopInfoContext.editedFiles[fileIndex].s3Key;

        
      const dimension:any = await getMediaDimension(shopInfoContext.editedFiles[fileIndex].file);
      
      if(dimension.result!==0){
        console.error(dimension.errMsg);
        return;
      }
      const stop =false;
      if(stop){
        setIsLoading(prev=>false);
        return
      }

      const aspectRatio = dimension.info.width/dimension.info.height


      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      const index = editorPageContext.selectIndex;
      const type = shopInfoContext.editedFiles[fileIndex].mainCategory==="image"? t("pages-editor-main-effectpanel.m1") : t("pages-editor-main-effectpanel.m2");
      finalScenario.scenes[index].fileName = fileName;
      finalScenario.scenes[index].layoutList[0].path = fileKey
      finalScenario.scenes[index].layoutList[0].type = type
      finalScenario.scenes[index].type = type;
      finalScenario.scenes[index].filter = filter;

      let effectList = JSON.parse(JSON.stringify(editorContext.screenEffectList))
      if(aspectRatio>16/9){
        // horizontal
        effectList[index].before.aspect = "horizontal"
      }else if(aspectRatio<16/9){
        //vertical
        effectList[index].before.aspect = "vertical"
      }else{
        // 16/9
        effectList[index].before.aspect = "none"
      }
      if(index>0){
        if(aspectRatio>16/9){
          // horizontal
          effectList[index-1].after.aspect = "horizontal"
        }else if(aspectRatio<16/9){
          //vertical
          effectList[index-1].after.aspect = "vertical"
        }else{
          // 16/9
          effectList[index-1].after.aspect = "none"
        }
      }
      editorContext.setScreenEffectList(effectList);

      if(index>0){
        await renderWithNewImage({finalScenario:finalScenario, sceneIndex:index-1, screenEffectList:effectList});
      }else{
        await renderWithNewImage({finalScenario:finalScenario, sceneIndex:index, screenEffectList: effectList});
      }
  
      scenarioContext.setFinalScenario(finalScenario);
      setIsLoading(prev=>false);
    }
  };

  const handleDelete = (fileName:string) => {
    const targetIndex = shopInfoContext.editedFiles.findIndex(item=>item.file.name === fileName)
    shopInfoContext.removeFile(shopInfoContext.editedFiles[targetIndex]);
  };
  
  const getFileExtension = (file: File)=>{
    const fileName = file.name;
    const fileNameSplit = fileName.split(".");
    const fileExtension = fileNameSplit[fileNameSplit.length - 1];
    return fileExtension;
  }

  const isValidExtension = (file: File) => {
    const fileName = file.name;
    const fileNameSplit = fileName.split(".");
    const fileExtension = fileNameSplit[fileNameSplit.length - 1];
    return validExtensions.includes(fileExtension.toLowerCase());
  };

  // 파일 중복 검사
  const isDuplicatedFile = (file: File) => {
    let retVal = false;
    shopInfoContext.files.forEach((fileInfo) => {
      if(fileInfo.file.name === file.name) {
        retVal = true;
      }
    });
    return retVal;
  };

  //비디오 길이 검사
  const checkVideoLength = async (file:File) => {
    try{
      const checkPromise = await new Promise(async(resolve,reject)=>{
        const url = URL.createObjectURL(file);
        const tempEl = document.createElement("video");
        tempEl.src=url;

        tempEl.addEventListener("loadedmetadata", (event) => {
          const target = event.currentTarget as HTMLVideoElement
          if(isLogging) console.log("check video :: ",target.duration)
          if(target){
            if(target.duration <= 5){
              resolve({result:0, errMsg:"length checked"})
            }else{
              resolve({result:-1, errMsg:"invalid length"})
            }
          }else{
            reject("video element error")
          }
        });
      })
      return checkPromise
    }catch(err){
      return ({result:-1, errMsg:err})
    }
  }

  const supernovaIntervalRef = useRef([]);
  async function genSupernova(fileInfoList){
    const fileList = fileInfoList.map(fileInfo=>fileInfo.file);
    let fileInfos = JSON.parse(JSON.stringify(fileInfoList))
    let supernovaStatusList = []
    // if(isLogging) console.log("Supernova request :: ", fileInfos);

    for(let index=0;index<fileInfos.length;index++){      
      const fileDemension:any = await getMediaDimension(fileList[index]);
      console.log("file demension :: ", fileDemension);
      if(fileDemension.result===0 && (fileDemension.info.width<=720 || fileDemension.info.height<=720)){
        // 파일 검사 성공 및 너비가 720p이하인 경우
        // needSupernova = true;
        supernovaStatusList.push({file:fileList[index].name, status:"ready", finished:false})
      }else{
        supernovaStatusList.push({file:fileList[index].name, status:"succeeded", finished:true})
      }
    }

    const supernovaPromise = await new Promise(async(resolve, reject)=>{
      fileInfos.map(async (fileInfo, index)=>{
        try{
          if(supernovaStatusList[index].finished){
            return
          }
          let type=0;
          if(isLogging) console.log('file type ::: ', fileList[index].type)
          if(fileList[index].type.split('/')[0]==="image"){
            type=0;
          }else{
            type=1;
          }
          
          const request = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            fileS3Key: fileInfo.s3Key
          }
          let postResult

          if(type===0){
            postResult = await WebPostSupernovaImage(request);
          }else if(type===1){
            postResult = await WebPostSupernovaVideo(request);
          }
      
          if(!postResult){
            console.error("Error while post supernova")
            throw Error(`Error while post supernova`)
          }
          if(postResult.result!==0){
            console.error("Post supernova error ::: ", postResult.errMsg);
            throw Error(`Post supernova error ::: ${postResult.errMsg}`)
          }
      
          try{
            const supernovaPromise:any = await new Promise(async (resolve, reject)=>{
              const supernovaInterval = setInterval(async ()=>{
                try{
                  const request={
                    assetId: sessionStorage.getItem("ASSET_ID"),
                    jobId:postResult.jobId
                  }
                  const getResult = await WebGetSupernova(request)
                  if(!getResult){
                    clearInterval(supernovaInterval);
                    reject(`Error while get supernova`)
                  }
                  if(getResult.result !== 0){
                    clearInterval(supernovaInterval);
                    reject(`Supernova error ::: ${getResult.errMsg}`)  
                  }
                  supernovaStatusList[index].status = getResult.status
                  switch(getResult.status){
                    case "SUBMITTED":
                    case "submitted":
                      break;
                    case "PENDING":
                    case "pending":
                      break;
                    case "RUNNABLE":
                    case "runnable":
                      break;
                    case "STARTING":
                    case "starting":
                      break;
                    case "RUNNING":
                    case "running":
                      break;
                    case "SUCCEEDED":
                    case "succeeded":
                      clearInterval(supernovaInterval);
                      if(isLogging) console.log(`Supernova ${supernovaStatusList[index].file} batch succeeded`);
                      fileInfos[index].s3Key = getResult.fileS3Key;
                      supernovaStatusList[index].finished = true;
                      resolve(getResult);
                      break;
                    case "FAILED":
                    case "failed":
                      clearInterval(supernovaInterval);
                      if(isLogging) console.log(`Supernova ${supernovaStatusList[index].file} batch failed... use original file\nerrMsg ::: ${getResult.errMsg}`);
                      supernovaStatusList[index].finished = true;
                      break;
                  }
                }catch(err){
                  console.error(err);
                  clearInterval(supernovaInterval)
                  reject(err);
                }
              }, 2000)
              supernovaIntervalRef.current.push(supernovaInterval);
            })          
          }catch(err){
            if(isLogging) console.error(err);
            supernovaStatusList[index].status = "failed";
            supernovaStatusList[index].finished = true
          }
        }catch(err){
          console.error(err)
        }
      })


      const finishPromise = await new Promise(async(resolve, reject)=>{
        const finishWatcher = setInterval(()=>{
          try{
            const isDone = supernovaStatusList.every(item=>item.finished === true)
            if(isDone){
              supernovaIntervalRef.current.forEach(id=>clearInterval(id))
              clearInterval(finishWatcher);
              for(let index=0; index<fileInfos.length; index++){
                fileInfos[index].file=fileList[index];
              }
              resolve(fileInfos);
            }else{
              if(isLogging) console.log(`Supernova waiting : `, supernovaStatusList.filter(item=>item.status!=="succeeded"))
            }
          }catch(err){
            console.error('promise watcher error : ',err)
            reject(undefined);
          }
        },5000)
      })

      resolve(finishPromise);
    })
    if(isLogging) console.log(`Supernova result :: `, supernovaPromise)
    return supernovaPromise;
    //////////////// todo :: complete this ///////////////////////////

  }


  const handleInsertNew = async (e) => {
    // 파일 추가 함수
    if(isLogging) console.log(`Insert new file`);
    const inputEl = document.createElement("input");
    inputEl.type="file"
    inputEl.multiple=true;
    inputEl.accept="video/mp4, image/png, image/jpeg, image/bmp";

    inputEl.addEventListener("change", async(event:any)=>{
      setIsLoading(prev=>true)
      const inputFiles = event.currentTarget.files;

      let newFileList = [];
      const failedFileList = [];

      if(shopInfoContext.files.length + inputFiles.length >10){
        if(isLogging) console.log('input file over 10')
        const alertText=<>
          <span>{t("pages-editor-main-effectpanel.m3")}</span>
        </>
        setAlertDescription(alertText);
        setShowAlert(true);
      }else{
        const assetId = sessionStorage.getItem("ASSET_ID");
        for(let inputFileIndex=0; inputFileIndex<inputFiles.length; inputFileIndex++){
          if (inputFiles[inputFileIndex]){
            // 파일이 있는 경우 확장자, 중복 검사
            if(!isValidExtension(inputFiles[inputFileIndex])) {
              const alertText=<>
                <span>{t("pages-editor-main-effectpanel.m4")}</span>
              </>
              setAlertDescription(alertText);
              setShowAlert(true);
              return;
            }else if(isDuplicatedFile(inputFiles[inputFileIndex])){
              const alertText=<>
                <span>{t("pages-editor-main-effectpanel.m5")} {`( ${inputFiles[inputFileIndex].name} )`}</span>
              </>
              setAlertDescription(alertText);
              setShowAlert(true);
              return;
            }       
          }

          if(getFileExtension(inputFiles[inputFileIndex])==="mp4"){
            // 동영상인 경우 길이 체크
            const result:any = await checkVideoLength(inputFiles[inputFileIndex]);
            if(result.result!=0){
              if(isLogging) console.error("video error :: ", result.errMsg);
              setShowAlert(true);
              setAlertDescription(t("pages-editor-main-effectpanel.m6"))
              continue;
            }else{
              if(isLogging) console.log(`${inputFiles[inputFileIndex].name} has valid length`)
            }
          }
          
          const typeName = inputFiles[inputFileIndex].type.split('/')[0];

          const normName = inputFiles[inputFileIndex].name.normalize("NFC")

          const renamedFile = new File([inputFiles[inputFileIndex]], normName, {
            type: inputFiles[inputFileIndex].type, // 기존 파일의 MIME 타입 유지
            lastModified: inputFiles[inputFileIndex].lastModified, // 기존 수정 시간 유지
          });
  
          const uploadFormData = new FormData();
          uploadFormData.append('assetId', assetId);
          uploadFormData.append('mainCategory', typeName);
          uploadFormData.append('subCategory', "original");
          uploadFormData.append('file', renamedFile);

          try{
            const uploadResult = await WebResourceUpload(uploadFormData);

            if(uploadResult.result===0){

              const presignedUrlResponse = await WebGetUrl({
                assetId: sessionStorage.getItem("ASSET_ID"),
                bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
                key: uploadResult.resourceInfo.s3key,
              })

              if(presignedUrlResponse.result===0){
                let newFileInfo:fileInfo = {
                  file: renamedFile,
                  s3Key: uploadResult.resourceInfo.s3key,
                  mainCategory:typeName,
                  subCategory:"original",
                  editInfo:undefined,
                  presignedUrl: presignedUrlResponse.presignedUrl
                }
                newFileList.push(newFileInfo);
              }else{
                failedFileList.push(renamedFile.name);
              }
            }else{
              failedFileList.push(renamedFile.name);
            }
          }catch(err){
            console.error("upload failed", err)
            failedFileList.push(renamedFile.name);
          }
        }

        if(newFileList.length!==0){
          // 모두 업로드 후 슈퍼노바 병렬실행행
          newFileList = await genSupernova(newFileList) as any
          if(!newFileList){
            supernovaIntervalRef.current.forEach(id=>clearInterval(id));
          }
        }

        if(failedFileList.length!==0){
          const alertText=<>
            <p>{t("pages-editor-main-effectpanel.m7")}</p>
            {failedFileList.map(item=>(<span>{item}, </span>))}
          </>
          setAlertDescription(alertText);
          setShowAlert(true);
        }
        console.log("new file list :: ",newFileList);
        shopInfoContext.addFile(newFileList);
      }
      setIsLoading(prev=>false)
    })
    
    inputEl.click();
  };

  // useEffect(()=>{
  //   console.log("files :: ", shopInfoContext.files)
  // },[shopInfoContext.files])

  const isUsedFile = (fileName:string) => {
    const fileList = scenarioContext.finalScenario.scenes.map(scene=>scene.fileName);
    const idx = fileList.findIndex(file=>file===fileName);
    if(idx<0){
      return false
    }else{
      return true
    }
  }


  return (
    <StyledTabContainer>
      {props.urlList.map((item, index)=>{
        const deletable = isUsedFile(item.name);
        const scene = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex];
        if(!scene || editorPageContext.selectIndex===undefined){return<></>}

        const layoutFile = scene.layoutList[0].path.split('/')[scene.layoutList[0].path.split('/').length-1];
        let isSelected = scene.fileName === item.name
        let isInpainted = isSelected && (layoutFile!==scene.fileName)
        const addable = editorPageContext.selectType==="video" && !isSelected;
        return <ImageListItem selected={isSelected} key={`image-list-item${index}`} url={item.url} contentType={item.type as any} property1="file" state="default" fileName={item.name} onAdd={handleAdd} onDelete={handleDelete} hasAddButton={addable||isInpainted} hasDeleteButton={!deletable}/>
      })}
      {/* <ImageListItem contentType="이미지" property1="file" state="default" fileName="test1" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test2" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test3" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test4" onAdd={handleAdd} onDelete={handleDelete}/> */}
      <ImageListItem key={`image-list-item-add`} contentType="이미지" property1="add" state="default" fileName="" onAdd={handleInsertNew} onDelete={null}/>      
      {
        showAlert&&
        <AlertModal
          style={{zIndex:100}}
          icon={alertIcon}
          description={alertDescription}
          show={showAlert}
          onCancel={closeAlert}
          onConfirm={closeAlert}
          onlyConfirm
        />
      }
    </StyledTabContainer>
  );
};

export const ImageTabContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const {setInpaintingCmd, setShowInpainting} = useContext(PanelContext)
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);

  const handleCommand = (name:string) => {
    console.log(`Image Command: ${name}`);

    const type = editorPageContext.selectType

    if(type==="video"){
      setInpaintingCmd(name);
      setShowInpainting(true);
    }

  };



  useEffect(()=>{
    const sceneIndex = editorPageContext.selectIndex
    if(sceneIndex!==undefined){
      const sceneData = scenarioContext.finalScenario.scenes[sceneIndex];
      

      console.log("SCENE TYPE ::: ", sceneData.type)
      switch(sceneData.type){
        case "이미지":
          setIsDisabled(false)
          break;
        default:
          setIsDisabled(true)
          break;
      }
    }else{
      setIsDisabled(true)
    }
  },[editorPageContext.selectIndex])


  return (
    <StyledTabContainer>
      <IconListItem Icon={AIEraserIcon} name={t("pages-editor-main-effectpanel.m8")} onCommand={()=>{handleCommand("remobj")}} disabled={isDisabled}/>  
      <IconListItem Icon={AIBackgroundIcon} name={t("pages-editor-main-effectpanel.m9")} onCommand={()=>{handleCommand("genbg")}} disabled={isDisabled}/>  
    </StyledTabContainer>  
  );
}

export const TransitionTabContainer = (): JSX.Element => {
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)
  
  const {isLoading, setIsLoading} = editorPageContext;
  const {filter}= useContext(PanelContext)

  const handleCommand = async (name:string) => {
    console.log(`Transition Command: ${name}`);

    if(editorPageContext.selectIndex===undefined){return}
    if(editorPageContext.selectIndex === scenarioContext.finalScenario.scenes.length-1){
      return;
    }

    if(editorPageContext.selectType==="video"){
      setIsLoading(prev=>true)
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      finalScenario.scenes[editorPageContext.selectIndex].effect = name;
      finalScenario.scenes[editorPageContext.selectIndex].filter = filter;
      scenarioContext.setFinalScenario(finalScenario);

      const currentScene = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex];
      const nextScene = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex+1]

      const sceneRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        sceneNo: editorPageContext.selectIndex,
        screenBefore: currentScene.layoutList[0].path,
        screenAfter: nextScene.layoutList[0].path,
        screenBeforeEffect: editorContext.screenEffectList[editorPageContext.selectIndex].before,
        screenAfterEffect:editorContext.screenEffectList[editorPageContext.selectIndex].after,
        effect: matchEffect(name),
        duration: currentScene.time,
        durationAfter: nextScene? nextScene.time : 3,
        mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
        serverType: process.env.REACT_APP_SERVER_TYPE,
        inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
        shopName:shopInfoContext.shopName,
        isDev: false,
        languageCode:shopInfoContext.language,
        filter: filter,
        nextFilter: nextScene.filter
      }

      const fileResponse:any = await WebPostPreviewScene(sceneRequest);
      if(fileResponse.status===200){
        const blob = new Blob([fileResponse.data],{type:"video/mp4"})
        const url = await URL.createObjectURL(blob);
        
        let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
        newList[editorPageContext.selectIndex] = url
        editorPageContext.setSceneVideoUrlList(prev=>newList);
      }else{
        // textUrlList.push('');
      }
    }
    setIsLoading(prev=>false)
  };

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "왼쪽으로 슬라이드":
        enName="Slide left"
        break;
      case "오른쪽으로 슬라이드":
        enName="Slide right"
        break;
      case "위로 슬라이드":
        enName="Slide up"
        break;
      case "아래로 슬라이드":
        enName="Slide down"
        break;
      case "원 화면전환1":
        enName="Circle 1"
        break;
      case "원 화면전환2":
        enName="Circle 2"
        break;
      case "페이드아웃 화면전환":
        enName="Fade out"
        break;
      case "없음":
        break;
    }
    return enName;
  }

  return (
    <StyledTabContainer>
      {
        transitionEffectData.map((effect, index)=>{
          const idx = screenEffectIconData.findIndex(item=>item.name === effect.effect);
          let icon = undefined;
          if(idx>=0){
            icon = `./${screenEffectIconData[idx].iconFile}`
          }

          let isSelected = false;
          if(editorPageContext.selectIndex!==undefined){
            isSelected = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]?.effect === effect.effect && editorPageContext.selectType==="video"
          }

          return(
            <IconListItem 
              selected={isSelected}
              key={`screen-effect-${index}`}
              src={icon}
              Icon={null}
              name={translate(effect.effect)} 
              onCommand={()=>{handleCommand(effect.effect)}}
            />        
          )
        })
      }
    </StyledTabContainer>  
  );
}

export const TextEffectTabContainer = (): JSX.Element => {
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext);
  const panelContext = useContext(PanelContext)

  const {isLoading, setIsLoading} = editorPageContext

  function matchFont(target){
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }
  function matchTextEffect(target){
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffectData[index].name
    }
  }

  const handleCommand = async (name:string) => {
    console.log(`Text Effect Command: ${name}`);
    if(editorPageContext.selectType==="text"&&editorPageContext.selectIndex!=undefined){
      setIsLoading(prev=>true)
      panelContext.setTextRendered(prev=>false)

      const {textIndex} = panelContext

      console.log(`request scene${editorPageContext.selectIndex} text${textIndex}`)

      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      if(finalScenario.scenes[editorPageContext.selectIndex].textList[textIndex]){
        finalScenario.scenes[editorPageContext.selectIndex].textList[textIndex].effect = name;        
      }
      const tempTextData = JSON.parse(JSON.stringify(finalScenario.scenes[editorPageContext.selectIndex].textList[textIndex]));
      scenarioContext.setFinalScenario(finalScenario);

      console.log(`Text Effect: ${name}`);
      const sceneData = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]
      const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
      const request = {
        "assetId":sessionStorage.getItem("ASSET_ID"),
        "text": sceneData.textList[textIndex].text,
        "fontSize": sceneData.textList[textIndex].height,
        "textWidth":450,
        "fontFile": matchFont(sceneData.textList[textIndex].font),
        "fontColor": sceneData.textList[textIndex].fontColor,
        "hasShadow": sceneData.fileName==="search"?false:true,
        "posX": sceneData.textList[textIndex].posX,
        "posY": sceneData.textList[textIndex].posY,
        "duration": sceneData.time,
        "rotate": sceneData.textList[textIndex].rotate||0,
        "textEffect": matchTextEffect(name),
        "sceneNo": editorPageContext.selectIndex,
        "shadowInfo":shadow,
        languageCode: shopInfoContext.language
      }
      
      const response:any = await WebPostPreviewText(request);
  
      if(response.status===200){
        const blob = new Blob([response.data],{type:"video/webm"})
        const url = URL.createObjectURL(blob);
        let urlList = JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
        urlList[editorPageContext.selectIndex][textIndex]=url;
        editorPageContext.setTextVideoUrlList(urlList)

        let newTextList = JSON.parse(JSON.stringify(panelContext.storedTextList));
        newTextList[editorPageContext.selectIndex][textIndex] = tempTextData;
        panelContext.setStoredTextList(newTextList);
      }
    }
    panelContext.setTextRendered(prev=>true)
    setIsLoading(prev=>false)
  };

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "출렁이는 효과":
        enName="Wave"
        break;
      case "인트로 효과":
        enName="Intro"
        break;
      case "날아오는 효과":
        enName="Fly"
        break;
      case "타자치는 효과":
        enName="Typing"
        break;
      case "반투명 효과":
        enName="Transparent"
        break;
      case "깜박이는 효과":
        enName="Blink"
        break;
      case "없음":
        enName="None"
        break;
    }
    return enName;
  }


  return (
    <StyledTabContainer>
      {
        textEffectData.map((effect, index)=>{
          // const textEffect = <video width={"100%"} src={`./text_thumb_${effect.name}.mp4`} autoPlay loop muted/>
          let src = `./text_thumb_${effect.name}`
          if(shopInfoContext.language==="en-us"){
            src+='_en'
          }
          src+='.mp4'
          if(effect.name==='none'){
            src = undefined
          }

          let isSelected =false;
          if(editorPageContext.selectIndex!==undefined){
            isSelected = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]?.textList[0].effect === effect.effect && editorPageContext.selectType==="text"
          }

          return(
            <IconListItem selected={isSelected} key={`text-effect-${index}`} src={src} Icon={null} name={translate(effect.effect)} onCommand={()=>{handleCommand(effect.effect)}}/>      
          )
        })
      }
      {/* <IconListItem Icon={null} name="출렁이는" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="여러번 깜박" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="등장하는" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="날아왔다 사라지는" onCommand={handleCommand}/> 
      <IconListItem Icon={null} name="반투명" onCommand={handleCommand}/> 
      <IconListItem Icon={null} name="타자치는" onCommand={handleCommand}/>  */}
    </StyledTabContainer>  
  );
}

export const BackgroundMusicTabContainer = (props): JSX.Element => {

  const [bgmPlaying, setBgmPlaying] = useState<string>("")
  const scenarioContext = useContext(ScenarioContext);


  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(<>
    <span>배경음악을 변경하시겠습니까?</span><br/>
    <span>변경 시 기존 배경음악이 모두 교체됩니다.</span>
  </>)  
  const [alertIcon, setAlertIcon] = useState(<AlertIcon/>)

  const [selectedBGM, setSelectedBGM] = useState(0);

  const handleCommand = async (title:string, command: string) => {
    console.log(`Background Music Command: ${title} , ${command}`);
    
    switch(command){
      case "play":
        setBgmPlaying(title);
        break;
      case "pause":
        setBgmPlaying("");
        break;
      case "add":
        setShowAlert(true);        
        const idx = scenarioContext.BGMInfo.findIndex(bgm=>bgm.musicTitle===title);
        setSelectedBGM(idx);
        // if(idx>=0){
        //   scenarioContext.setSelectedBgmIndex(idx);
        // }
        break;
    }
  };

  function changeBGM(){
    if(selectedBGM>=0){
      scenarioContext.setSelectedBgmIndex(selectedBGM);
    }

    setShowAlert(false);
  }

  /// TODO 
  /// MusicListItem의 addButton 클릭 시 scenarioContext.BGMInfo[]의 해당 인덱스 정보를 트랙에 추가

  return (
    <StyledTabContainer>
      {props.bgmList.map((bgm, index)=>{
        return <MusicListItem selected={scenarioContext.selectedBgmIndex===index} key={`music-list-item${index}`} title={bgm.title} artist="" time={bgm.duration} state={bgmPlaying===bgm.title?"play":"normal"} onCommand={handleCommand} url={bgm.url}/>  
      })}
      {/* <MusicListItem url={undefined} title="BGM 제목" artist="ddddd" time="00:15:00" state="play" onCommand={handleCommand}/>
      <MusicListItem url={undefined} title="28272" artist="fadfee" time="00:20:00" state="normal" onCommand={handleCommand}/> */}

      {
        showAlert&&
        <AlertModal
          icon={alertIcon}
          description={alertMessage}
          show={showAlert}
          onConfirm={changeBGM}
          onCancel={()=>{setShowAlert(false)}}
          style={{zIndex:20000}}
        />
      }

    </StyledTabContainer>  
  );
}