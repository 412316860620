import styled, {keyframes} from "styled-components";

import { ReactComponent as BlueCharacter } from "../../../Assets/CharacterAsset/blue.svg"

import Lottie from "lottie-react";
import characterAnimation from '../../../animation/character.json'

export const ProgressBarStyle = styled.div`
  user-select:none;
  text-decoration:none;
  width: 412px;
  height: 64px;
  box-sizing:border-box;
  overflow:hidden;
  border-radius:${(props)=>props.theme.radius["radius-05"]};
  outline:${(props)=>`1px solid ${props.theme.colors["border-active"]}`};
  padding: ${(props)=>`12px ${props.theme.spacing["spacing-07"]} 12px 40px`};
  background-color:${(props)=>props.theme.colors["background-primary"]};
  position:relative;
  display:flex;
  cursor:pointer;   
  color:${(props)=>props.theme.colors["text-primary"]};
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  text-decoration: none;
  
  &.ing{
    background-color:${(props)=>props.theme.colors["background-primary"]};
    cursor:default;
    color:${(props)=>props.theme.colors["text-primary"]};
    text-decoration: none;
  }
  &.done{
    outline:none;
    background-color:${(props)=>props.theme.colors["button-brand2-subtle"]};
    color:${(props)=>props.theme.colors["text-tertiary"]};
    text-decoration: none;
  }
  &.todo{
    outline:none;
    background-color:${(props)=>props.theme.colors["button-brand2-subtle"]};
    color:${(props)=>props.theme.colors["text-tertiary"]};
    text-decoration: none;
  }

  &:hover{
    outline:${(props)=>`1px solid ${props.theme.colors["border-active"]}`};
    text-decoration: none;
  }
`
export const NumberDivStyle = styled.div`
  position:absolute;
  left: 16px;
  top: 8px;
  color:${(props)=>props.theme.colors["text-brand1"]};
  ${(props)=>props.theme.typography["pre-body-05"]};
  text-decoration: none;
`
  
export const ContentDivStyle = styled.div`
  display:flex;
  width:100%;
  align-items:center;
  flex-direction:row;
  left 40px;
  justify-content: space-between;
  gap:${(props)=>props.theme.spacing["spacing-03"]};
  
  z-index:1;
  text-decoration: none;
`

export const ProgressTextWrapperStyle = styled.div`  
  ${(props)=>props.theme.typography["pre-body-03"]};
  transition: font-size 0.5s ease;  

  &.ing{
    ${(props)=>props.theme.typography["pre-body-01"]};    
    transition: font-size 0.5s ease;
  }
`


const moveUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const BlueCharacterStyle = styled.div`
  position: absolute;
  top: 4px;
  right: 88px;
  z-Index: 0;
  opacity:0;
  &.ing{
    animation: ${moveUp} 0.5s ease-out forwards;
    display:flex;
  }
`


// export const BlueCharacterStyle = styled(BlueCharacter)`
//   position: absolute;
//   top: 4px;
//   right: 88px;
//   z-Index: 0;
//   opacity:0;
//   &.ing{
//     animation: ${moveUp} 0.5s ease-out forwards;
//     display:flex;
//   }
// `
