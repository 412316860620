import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography, Slider, Stack, IconButton } from "@mui/material";
import styled from "styled-components";
import { Icon29 } from "../../../../../../../Components/pages/icons/Icon29";
import { Icon166 } from "../../../../../../../Components/pages/icons/Icon166";
import { AIToggleButtons } from "../../../../../../../Components/pages/Editor/AIToggleButtons";
import { ImageListItem } from "../../../../../../../Components/pages/Editor/ImageListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { WebGetUrl, WebPostPreviewScene, WebResourceDelete, WebResourceUpload } from "../../../../../../../Api/resource";
import { EditorPageContext } from "../../../../../editorPageContext";

import { useTranslation } from "react-i18next";

import { ENABLE_I2V } from "../../../../../../../Utilities";

import { WebPostInpaintingEraser, WebPostInpaintingBG, WebGetAIGernatingStatus } from "../../../../../../../Api/ai";

import { getTimeString, matchEffect } from "../../../../../../../Utilities";

import Modal from "../../../../../../../Components/common/Modal"
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";
import Button from "../../../../../../../Components/common/Button";
import { resolve } from "path";
import LoadingScreen from "../../../../../../../Components/common/LoadingScreen";
import Textarea from "../../../../../../../Components/common/Textarea";

import { ReactComponent as EraserIcon } from "../../../../../../../Assets/icon-editor/ai-eraser.svg"
import { ReactComponent as BackgroundIcon } from "../../../../../../../Assets/icon-editor/ai-background.svg"

import theme from "../../../../../../../styles/theme";
import { a } from "../../../../../../../Components/pages/ShopInfo/ImageCropper/useDebounceEffect";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";

import InpaintingModal from "./InpaintingModal";
import { PanelContext } from "../../../../PanelContext";

const StyledFrame = styled.div`
  position:relative;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
  transform: none;
`;

const IconButtonStyle = {
  // all: "unset",
  width: "40px",
  height: "40px",
  backgroundColor: theme.colors["background-primary"],
  padding: theme.spacing["spacing-03"],
  borderRadius: "8px",
  border: `1px solid`,
  borderColor: theme.colors["border-secondary"],
  color: theme.colors["icon-secondary"],

  "&:hover":{
    // background:"red"
  },
  "&.selected":{
    backgroundColor: theme.colors["background-secondary"],
    borderColor: theme.colors["border-active"],
    color: theme.colors["icon-brand1"]
  }
}

const InpaintImageWrapper = styled.div`
  display: flex;
  gap: ${props=>props.theme.spacing["spacing-04"]};
`

const LoadingScreenWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color:rgba(0,0,0,0.4);
  display:flex;
  justify-content: center;
  align-items:center;  
`

export const AIStyleTabContent = ({index}): JSX.Element => {
  const { t } = useTranslation();
  const [size, setSize] = useState(0);
  const [aistyle, setAistyle] = useState('ai-erase');

  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)

  const [aiImageList, setAiImageList] = useState([]);
  const [aiImageUrlList, setAiImageUrlList] = useState([]);

  const {isLoading, setIsLoading} = editorPageContext

  const {eraserList, genBgList} = editorContext;
  const {setEraserList, setGenBgList} = editorContext


  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===(editorPageContext.selectIndex+1));
      if(idx>=0){
        const targetImages = scenarioContext.generatedImages[idx];
        setAiImageList(targetImages.s3Keys);
      }else{
        setAiImageList([]);
      }
    }
  },[scenarioContext.generatedImages, editorPageContext.selectIndex])

  useEffect(()=>{
    getUrls();
  },[aiImageList])

  async function getUrls(){
    const newList = [];
    for(let i=0; i<aiImageList.length;i++){
      const response = await WebGetUrl({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: aiImageList[i].includes("i2v_client")?process.env.REACT_APP_OUTPUT_BUCKET_NAME : process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: aiImageList[i]
      })
      if(response.result===0){
        newList.push(response.presignedUrl);
      }else{
        console.error("Failed to get url")
      }
    }
    setAiImageUrlList(newList);
  }

  const handleChangeAiStyle = (newValue: string) => {
    setAistyle(newValue);
  };

  const handleChangeSize = (event: Event, newValue: number | number[]) => {
    setSize(newValue as number);
  };

  const handleAdd = (index:number) => {
    // console.log(`Add ai image : ${index}`);
    if(editorPageContext.selectIndex===undefined){return}

    const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===(editorPageContext.selectIndex+1));
    if(idx>=0){
      const genImages = scenarioContext.generatedImages[idx];
      let genImageList = JSON.parse(JSON.stringify(scenarioContext.generatedImages))
      genImageList[idx].selectedS3KeyIndex = index
      scenarioContext.setGeneratedImages(genImageList)
  
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      finalScenario.scenes[editorPageContext.selectIndex].fileName="없음" //t("pages-editor-main-editingpanel.m1")
      finalScenario.scenes[editorPageContext.selectIndex].type="AI 생성 이미지" //t("pages-editor-main-editingpanel.m2")
      finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].type="AI 생성 이미지" //t("pages-editor-main-editingpanel.m2")
      finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].path=genImages.s3Keys[index];
      scenarioContext.setFinalScenario(finalScenario);
    }
  };

  const handleAddInpainting = async (index:number, cmd:string) => {
    let selectedInpainting = undefined
    switch(cmd){
      case "remobj":
        selectedInpainting = eraserList[index]
        break;
      case "genbg":
        selectedInpainting = genBgList[index]
        break;
    }

    if(!selectedInpainting){
      return
    }

    setIsLoading(prev=>true)

    const sceneIndex = editorPageContext.selectIndex;
    const inpaintingName = selectedInpainting.s3Key.split("/")[selectedInpainting.s3Key.split("/").length-1];
    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
    finalScenario.scenes[sceneIndex].layoutList[0].path = selectedInpainting.s3Key;
    finalScenario.scenes[sceneIndex].type = "이미지"

    console.log("selectedInpainting :: " ,selectedInpainting);
    console.log("Scene :: ", finalScenario.scenes[sceneIndex]);
    
    let effectList = JSON.parse(JSON.stringify(editorContext.screenEffectList));
    
    effectList[sceneIndex].before.aspect="none"
    if(sceneIndex>0){
      effectList[sceneIndex-1].after.aspect="none"
    }

    const isLast = finalScenario.scenes.length-1 === sceneIndex;

    const currentScene = finalScenario.scenes[sceneIndex]
    const nextScene = isLast? currentScene : finalScenario.scenes[sceneIndex+1];
    
    const request = {
      assetId: sessionStorage.getItem("ASSET_ID"),
      sceneNo: sceneIndex,
      screenBefore: currentScene.layoutList[0].path,
      screenAfter: isLast? "black": nextScene.layoutList[0].path,
      screenBeforeEffect: effectList[sceneIndex].before,
      screenAfterEffect: effectList[sceneIndex].after,
      effect: isLast? "none":matchEffect(currentScene.effect),
      duration: currentScene.time,
      durationAfter: isLast? 3 : currentScene.time,
      mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
      serverType: process.env.REACT_APP_SERVER_TYPE,
      inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
      outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
      shopName: shopInfoContext.shopName,
      isDev: false,
      languageCode:shopInfoContext.language,
      filter: currentScene.filter,
      nextFilter: nextScene.filter,
    }
    
    const response:any = await WebPostPreviewScene(request);

    if(response.status===200){
      let newList =JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
      const blob = new Blob([response.data],{type:"video/mp4"})
      const url = await URL.createObjectURL(blob);
      newList[sceneIndex] = url;
      editorPageContext.setSceneVideoUrlList(newList);
      editorContext.setScreenEffectList(effectList);
    }else{
      
    }
    scenarioContext.setFinalScenario(finalScenario);
    setIsLoading(prev=>false)

  };
  
  const handleDelete = (fileName:string) => {
    // console.log(`Delete file: ${fileName}`);
  };
  const handleInsertNew = () => {
    // console.log(`Insert new file`);
  };

  const [bgUrlList, setBgUrlList] = useState([]);
  useEffect(()=>{
    getAiBgUrls();
  },[editorPageContext.aiBgList, editorPageContext.selectIndex])  

  async function getAiBgUrls(){
    const newList = []
    const sceneIndex = editorPageContext.selectIndex;
    if(sceneIndex!==undefined){
      for(let idx=0; idx<editorPageContext.aiBgList[sceneIndex].length; idx++){
        const request = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: editorPageContext.aiBgList[sceneIndex][idx]
        }
        const response = await WebGetUrl(request);
        
        if(response.result===0){
          newList.push(response.presignedUrl)
        }
      }
    }
    setBgUrlList(newList);
  }

  const {showInpainting, inpaintingCmd, setShowInpainting, setInpaintingCmd} = useContext(PanelContext)

  const handleCloseInpainting=()=>{setShowInpainting(false)}

  const onClickEraser = () => {
    console.log("Enable inpainting")
    editorPageContext.setIsInpainting(!editorPageContext.isInpainting);
    setShowInpainting(true)
  }

  const handleClickEraser = () => {
    setInpaintingCmd("remobj")
    // setShowInpainting(true)
  }
  const handleClickGenBg = () => {
    setInpaintingCmd("genbg")
    // setShowInpainting(true)
  }

  const handelDeleteInpaint = async (cmd:string, index:number) => { 
    let target 
    switch(cmd){
      case "remobj":
        target = eraserList[index];
        break;
      case "genbg":
        target = genBgList[index];
        break;
    }

    if(!target){
      return
    }

    setIsLoading(prev=>true);

    const deleteRequest = {
      assetId: sessionStorage.getItem("ASSET_ID"),
      resourceId: target.s3Key
    }

    let response:any
    try{
      response = await WebResourceDelete(deleteRequest);
    }catch(err){
      console.error(err);
    }
    
    if(response.result!=0){
      console.error(`Delete Error:: ${response.errMsg}`)
    }
    
    switch(cmd){
      case "remobj":
        setEraserList(prev=>{
          const newArr = JSON.parse(JSON.stringify(prev));
          newArr.splice(index,1);
          return newArr;
        })
        break;
      case "genbg":
        setGenBgList(prev=>{
          const newArr = JSON.parse(JSON.stringify(prev));
          newArr.splice(index,1);
          return newArr;
        })
        break;
    }
    setIsLoading(prev=>false);
  }

  return (
    <StyledFrame>
      <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
        {
          aiImageUrlList.map((url, index)=>{
            return(
              <ImageListItem contentType={t(ENABLE_I2V?"pages-editor-main-effectpanel.m2":"pages-editor-main-effectpanel.m1")} url={url} property1="file" state="default" fileName={t("pages-editor-main-editingpanel.m4", {num: index+1})} onAdd={()=>{handleAdd(index)}} onDelete={handleDelete} hasAddButton/>
            )
          })
        }
        {bgUrlList?.map(url=>{
          return <ImageListItem contentType="이미지" url={url} property1="file" state="default" fileName="생성 이미지 1" onAdd={()=>{}} onDelete={()=>{}}/>
        })}
      </Stack>
      {showInpainting && 
        <InpaintingModal
          show={showInpainting}
          handleClose={handleCloseInpainting}
          closeOnClickOutside={false}
          setEraserList={setEraserList}
          setGenBgList={setGenBgList}
          cmd={inpaintingCmd}
        />
      }
      <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
        <IconButton className={inpaintingCmd==="remobj"?'selected':undefined} sx={IconButtonStyle} onClick={handleClickEraser}>
          <EraserIcon style={{width:"100%", height:"100%"}} className={'class'}/>
        </IconButton>
        <IconButton className={inpaintingCmd==="genbg"?'selected':undefined} sx={IconButtonStyle}  onClick={handleClickGenBg}>
          <BackgroundIcon style={{width:"50px", height:"50px"}} className={'class'}/>
        </IconButton>
      </Box>
      {/* <AIToggleButtons value={aistyle} onChange={handleChangeAiStyle}/> */}

      <InpaintImageWrapper>
        {
          inpaintingCmd==="remobj"?
          eraserList.map((inpainting, index)=>{
            let isSelected = false;
            if(editorPageContext.selectIndex!==undefined){
              isSelected = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].path == inpainting.s3Key
            }
            if(inpainting){
              return(
                <ImageListItem selected={isSelected} key={`inpainting-eraser-${index}`} contentType={t("pages-editor-main-effectpanel.m1")} url={inpainting.presignedUrl} property1="file" state="default" fileName={t("pages-editor-main-editingpanel.m4", {num: index+1})} onAdd={()=>{handleAddInpainting(index,"remobj")}} onDelete={()=>handelDeleteInpaint("remobj",index)} hasAddButton={!isSelected} hasDeleteButton={!isSelected}/>  
              )
            }else{
              return undefined
            }
          })
          :
          genBgList.map((inpainting, index)=>{
            let isSelected = false;
            if(editorPageContext.selectIndex !==undefined){
              isSelected = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].path == inpainting.s3Key
            }
            if(inpainting){
              return(
                <ImageListItem selected={isSelected} key={`inpainting-bg-${index}`} contentType={t("pages-editor-main-effectpanel.m1")} url={inpainting.presignedUrl} property1="file" state="default" fileName={t("pages-editor-main-editingpanel.m4", {num: index+1})} onAdd={()=>{handleAddInpainting(index, "genbg")}} onDelete={()=>handelDeleteInpaint("genbg",index)} hasAddButton={!isSelected} hasDeleteButton={!isSelected}/>  
              )
            }else{
              return undefined
            }
          })
        }
      </InpaintImageWrapper>
    </StyledFrame>
  );
};

