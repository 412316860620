import React, { useEffect, useState, useContext, useRef } from "react";
import { Box, Grid, Typography, Select, MenuItem, Slider, TextField, IconButton, Paper } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { InputStepper } from "../../../../../../../Components/pages/Editor/InputStepper";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { ToggleButtons } from "../../../../../../../Components/pages/Editor/ToggleButtons";
// import { MasherContext } from "../../../../../../../Components/Masher";
import { EditorPageContext } from "../../../../../editorPageContext";
import { ImageListItem } from "../../../../../../../Components/pages/Editor/ImageListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";

import screenFilterData from "../../../../../../../Data/screenFilterData.json"
import { EditingPanelContext } from "../../../EditingPanelContext";

import { useTranslation } from "react-i18next";

import Button from "../../../../../../../Components/common/Button";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";
import { WebPostPreviewScene } from "../../../../../../../Api/resource";

import { matchEffect } from "../../../../../../../Utilities";
import { resolve } from "path";
import { PanelContext } from "../../../../PanelContext";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const StyledFilterBox = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  background-size: cover;
  // background-repeat: no-repeat;
  border: 1px solid;
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-radius: 10px;
  height: 72px;
  position: relative;
  width: 128px;
  overflow:hidden;
`
const FilterCanvas= styled.canvas`
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

const StyledFilterWrapper = styled.div`
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-04-duplicate);
  justify-content: center;
  padding: 0px 0px var(--tokens-spacing-07-duplicate) 0px;
  position: relative;
  width: 100%;

  .frame-20 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .text-wrapper-13 {
    color: var(--tokens-color-text-secondary-duplicate);
    flex: 1;
    font-family: var(--pre-caption-01-font-family);
    font-size: var(--pre-caption-01-font-size);
    font-style: var(--pre-caption-01-font-style);
    font-weight: var(--pre-caption-01-font-weight);
    letter-spacing: var(--pre-caption-01-letter-spacing);
    line-height: var(--pre-caption-01-line-height);
    margin-top: -1px;
    position: relative;
  }

  .icon-29-instance {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }

  .placeholder-5 {
    color: var(--tokens-color-text-tertiary-duplicate);
    flex: 1;
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    position: relative;
  }

  .img-2 {
    background-color: var(--tokens-color-background-secondary-duplicate);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-none-duplicate);
    height: 72px;
    position: relative;
    width: 128px;
  }

  .frame-21 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-04-duplicate);
    position: relative;
    width: 100%;
  }

  .frame-22 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  .placeholder-6 {
    color: var(--tokens-color-text-tertiary-duplicate);
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    margin-top: -1px;
    position: relative;
    width: 40px;
  }

  .bar-7 {
    flex: 1 !important;
    flex-grow: 1 !important;
    position: relative !important;
    width: unset !important;
  }

  .bar-8 {
    width: 52px !important;
  }

  .bar-9 {
    width: 46px !important;
  }

  .bar-10 {
    left: 40px !important;
  }

  .input-stepper-3 {
    align-items: center;
    background-color: var(--tokens-color-background-primary-duplicate);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: var(--tokens-spacing-06-duplicate);
    height: 32px;
    padding: 0px var(--tokens-spacing-04-duplicate) 0px var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  .frame-23 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }

  .placeholder-7 {
    color: var(--tokens-color-text-primary-duplicate);
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }

  .step-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .icon-4 {
    height: 12px !important;
    position: relative !important;
    width: 12px !important;
  }
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const VideoTabContent = ({index}): JSX.Element => {

  const { t } = useTranslation();

  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [degree, setDegree] = useState(0);
  const [rotation, setRotation] = useState('left');
  const [opacity, setOpacity] = useState(0);

  // const {editor} = useContext(MasherContext);
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const editorContext = useContext(EditorContext)

  // useEffect(()=>{
  //   const targetClip = editorPageContext.selectedItem.clip;
  //   if(targetClip){
  //     targetClip.setContainerValue("lock", "none")
  //     targetClip.setContainerValue("offW",true)
  //     targetClip.setContainerValue("offS",true)
  //   }
  // },[editorPageContext.selectedItem.clip])


  const handleChangeX = (event: Event, newValue: number | number[]) => {
    const sizeX = newValue as number;
    setSizeX(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("width", sizeX/100);
    // }
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    const sizeY = newValue as number;
    setSizeY(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("height", sizeY/100);
    // }
  };

  const handleChangeOpacity = (event: Event, newValue: number | number[]) => {
    const val = newValue as number
    // console.log("opacity change :: ", val);
    setFilter(filter=>({...filter, intensity:(val/100).toFixed(2)}))
    // setOpacity(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // /// editorPageContext에서 현재 선택된 클립 선택
    // targetClip.setContainerValue("opacity", (newValue as number)/100)
    // //clip.setContainerValue, clip.setContentValue 등으로 수치 조정
  };


  const handleChangePositionX = (newValue: number | number[]) => {
    const posX = newValue as number; //pixel
    const posXConvert = posX/1920; //ratio
    // console.log(`posX${posX} / ${posXConvert}`)
    setPositionX(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("x", posXConvert);
    // }
  };

  const handleChangePositionY = (newValue: number | number[]) => {
    const posY = newValue as number; // pixel
    const posYConvert = posY/1080; //ratio
    setPositionY(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("y", posYConvert);
    // }
  };

  // useEffect(()=> {
  //   console.log(rotation);
  // }, [rotation]);

  const [url, setUrl] = useState(undefined);
  const [filterUrl, setFilterUrl] = useState(undefined);
  const filterPreviewRef = useRef(null)
  // const [filter, setFilter] = useState({name:"none", intensity:1});
  const editingPanelContext = useContext(EditingPanelContext);
  const {filter, setFilter} = useContext(PanelContext);
  const [scene, setScene] = useState(undefined);
  useEffect(()=>{
    const scene = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex];
    if(!scene || editorPageContext.selectIndex===undefined){return}
    const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName);
    if(fileIndex<0){
      setUrl(undefined)
      return
    }

    const file = shopInfoContext.editedFiles[fileIndex].file;

    let url = undefined;
    if(shopInfoContext.editedFiles[fileIndex].mainCategory==="image"){
      url = URL.createObjectURL(file);

      setScene(scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex]);
      setFilter(scene.filter);
      setUrl(url);
      setFilterUrl(url);
    }else{
      getVideoThumbnail();
    }

  },[editorPageContext.selectIndex, scenarioContext.finalScenario])


  const getVideoThumbnail = async () => {
    try{
      const scene = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex];
      if(!scene || editorPageContext.selectIndex===undefined){return}
      const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName);
      if(fileIndex<0){
        setUrl(undefined)
        throw Error("file not exist");
      }
  
      const file = shopInfoContext.editedFiles[fileIndex].file;
  
      let videoUrl = URL.createObjectURL(file);

      const promise = await new Promise(async(resolve, reject)=>{
        const tempVideo = document.createElement("video") as HTMLVideoElement;
        tempVideo.src = videoUrl;
        tempVideo.currentTime = 0;
  
        tempVideo.onloadeddata = () => {
          tempVideo.currentTime = 0;          
        }
        tempVideo.onerror = (error) => {
          reject(error)
        }

        tempVideo.onseeked = ()=>{
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = tempVideo.videoWidth;
          canvas.height = tempVideo.videoHeight;
          ctx.drawImage(tempVideo, 0, 0, tempVideo.videoWidth, tempVideo.videoHeight);
  
          const imageDataUrl = canvas.toDataURL('image/png');
          resolve(imageDataUrl);
        }
      })

      const url = promise;
      setFilterUrl(url);
      setScene(scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex]);
      setFilter(scene.filter);
      setUrl(videoUrl);
      return promise
    }catch(err){
      console.error("failed to get thumbnail :: ", err)
      return undefined;
    }
  }

  useEffect(()=>{
    const el = filterPreviewRef.current as HTMLElement;
    if(el){
      // el.style.backgroundImage = `url(${filterUrl})`
      drawOriginal();
    }
  },[filterUrl])

  const [type, setType] = useState("이미지");
  useEffect(()=>{
    if(scene?.type){
      if(shopInfoContext.language==="en-us"){
        switch(scene.type){
          case "이미지":
            setType("image")
            break;
          case "동영상":
            setType("video")
            break;
        }
      }else{  
        setType(scene.type)
      }
    }
  },[scene, shopInfoContext.language])

  const handleChangeFilter = (e) => {
    setFilter(filter=>({...filter, name:e.target.value}))
  }

  const canvasRef = useRef(null);
  const originalCanvas = useRef(null);

  function curveValue(value, points) {
    const normalizedValue = value / 255;
    let result = 0;
  
    for (let i = 0; i < points.length - 1; i++) {
      const [x1, y1] = points[i];
      const [x2, y2] = points[i + 1];
  
      if (normalizedValue >= x1 / 255 && normalizedValue <= x2 / 255) {
        const t = (normalizedValue - x1 / 255) / (x2 / 255 - x1 / 255);
        result = y1 + t * (y2 - y1);
        break;
      }
    }
  
    return result;
  }
  function applySaturation(r, g, b, saturation) {
    const gray = 0.3 * r + 0.59 * g + 0.11 * b;
    r += (r - gray) * (saturation - 1);
    g += (g - gray) * (saturation - 1);
    b += (b - gray) * (saturation - 1);
    return [r, g, b];
  }

  function drawOriginal(){
    const imageEl = document.createElement("img");
    imageEl.src= filterUrl;
    imageEl.onload = () => {
      const canvas = originalCanvas.current as HTMLCanvasElement;
      if(canvas){
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageEl, 0, 0, canvas.width+1, canvas.height+1)
      }
    }
  }

  function redrawCanvas(filter){

    const imageEl = document.createElement("img");
    imageEl.src= filterUrl;
    imageEl.onload = () => {
      const canvas = canvasRef.current as HTMLCanvasElement;
      if(canvas){
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageEl, 0, 0, canvas.width+1, canvas.height+1)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        const idx = screenFilterData.findIndex(item=>item.name == filter.name);
        const filterCurve = screenFilterData[idx].curve;
        const filterEq = screenFilterData[idx].eq;

        for (let i = 0; i < data.length; i += 4) {
          let r = curveValue(data[i], filterCurve.red)
          let g = curveValue(data[i + 1], filterCurve.green)
          let b = curveValue(data[i + 2], filterCurve.blue)

          r += filterEq.brightness;
          g += filterEq.brightness;
          b += filterEq.brightness;
      
          r = (r - 128) * filterEq.contrast + 128;
          g = (g - 128) * filterEq.contrast + 128;
          b = (b - 128) * filterEq.contrast + 128;
      
          [r, g, b] = applySaturation(r, g, b, filterEq.saturation);

          data[i] = Math.max(0, Math.min(255, r));
          data[i + 1] = Math.max(0, Math.min(255, g));
          data[i + 2] = Math.max(0, Math.min(255, b));
        }
      
        // Put image data back
        ctx.putImageData(imageData, 0, 0);
      }else{
        console.error('canvas not exist')
      }
    }
  }


  const [filterName, setFilterName] = useState("none");
  useEffect(()=>{
    if(filter){
      setFilterName(filter.name)
    }
  },[filter])

  useEffect(()=>{
    if(filter){
      redrawCanvas(filter)
    }
  },[filterName, filterUrl])

  const {isLoading, setIsLoading} = useContext(EditorPageContext)

  const handleClickApply = async () => {
    setIsLoading(prev=>true);
    try{
      const sceneIndex = editorPageContext.selectIndex
      if(sceneIndex===undefined){throw Error("Undefined selectIndex")}
      let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
      let tempFinalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario))
  
      const currentScene = scenarioContext.finalScenario.scenes[sceneIndex];
      const nextScene = scenarioContext.finalScenario.scenes[sceneIndex+1]
  
      const isLast = sceneIndex === scenarioContext.finalScenario.scenes.length-1
  
      const request = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        sceneNo: sceneIndex,
        screenBefore: currentScene.layoutList[0].path,
        screenAfter: isLast? "black": nextScene.layoutList[0].path,
        screenBeforeEffect: editorContext.screenEffectList[sceneIndex].before,
        screenAfterEffect:editorContext.screenEffectList[sceneIndex].after,
        effect: isLast? "none":matchEffect(currentScene.effect),
        duration: currentScene.time,
        durationAfter: isLast? 3 : nextScene.time,
        mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
        serverType: process.env.REACT_APP_SERVER_TYPE,
        inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
        shopName: shopInfoContext.shopName,
        isDev: false,
        languageCode:shopInfoContext.language,
        filter: filter,
        nextFilter: isLast? currentScene.filter:nextScene.filter,
      }
      
      const response:any = await WebPostPreviewScene(request);
      
      if(response.status===200){
        // const blob = await response.blob()
        const blob = new Blob([response.data],{type:"video/mp4"})
        const url = await URL.createObjectURL(blob);
        newList[sceneIndex] = url;
                
        tempFinalScenario.scenes[sceneIndex].filter = filter;
      }else{
        newList[sceneIndex] = "";
      }


      if(sceneIndex!=0){  
        const prevIndex = editorPageContext.selectIndex -1 
    
        const prevScene = scenarioContext.finalScenario.scenes[prevIndex];
        const prevNextScene = scenarioContext.finalScenario.scenes[prevIndex+1]
    
        const isPrevLast = prevIndex === scenarioContext.finalScenario.scenes.length-1
    
        const request = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          sceneNo: prevIndex,
          screenBefore: prevScene.layoutList[0].path,
          screenAfter: isPrevLast? "black": prevNextScene.layoutList[0].path,
          screenBeforeEffect: editorContext.screenEffectList[prevIndex].before,
          screenAfterEffect:editorContext.screenEffectList[prevIndex].after,
          effect: isPrevLast? "none":matchEffect(prevScene.effect),
          duration: prevScene.time,
          durationAfter: isPrevLast? 3 : prevNextScene.time,
          mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
          serverType: process.env.REACT_APP_SERVER_TYPE,
          inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
          shopName: shopInfoContext.shopName,
          isDev: false,
          languageCode:shopInfoContext.language,
          filter: prevScene.filter,
          nextFilter: filter,
        }
        
        const response:any = await WebPostPreviewScene(request);
    
        if(response.status===200){
          // const blob = await response.blob()
          const blob = new Blob([response.data],{type:"video/mp4"})
          const url = await URL.createObjectURL(blob);
          newList[prevIndex] = url;          
        }else{
          newList[prevIndex] = "";
        }
      }
      scenarioContext.setFinalScenario(tempFinalScenario);
      editorPageContext.setSceneVideoUrlList(newList);
      setIsLoading(prev=>false);
    }catch(err){
      console.error("Error ::: ",err)
      setIsLoading(prev=>false);
      return;
    }
  }

  const handleClickApplyAll = async () => {
    setIsLoading(prev=>true)
    const idx = editorPageContext.selectIndex
    let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
    let tempFinalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario))
    let flags = scenarioContext.finalScenario.scenes.map((scene)=>"ready");

    scenarioContext.finalScenario.scenes.forEach(async (scene, sceneIndex)=>{
      try{
        const currentScene = scenarioContext.finalScenario.scenes[sceneIndex];
        const nextScene = scenarioContext.finalScenario.scenes[sceneIndex+1]
    
        const isLast = sceneIndex === scenarioContext.finalScenario.scenes.length-1
    
        const request = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          sceneNo: sceneIndex,
          screenBefore: currentScene.layoutList[0].path,
          screenAfter: isLast? "black": nextScene.layoutList[0].path,
          screenBeforeEffect: editorContext.screenEffectList[sceneIndex].before,
          screenAfterEffect:editorContext.screenEffectList[sceneIndex].after,
          effect: isLast? "none":matchEffect(currentScene.effect),
          duration: currentScene.time,
          durationAfter: isLast? 3 : nextScene.time,
          mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
          serverType: process.env.REACT_APP_SERVER_TYPE,
          inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
          shopName: shopInfoContext.shopName,
          isDev: false,
          languageCode:shopInfoContext.language,
          filter: filter,
          nextFilter: filter,
        }
        
        const response:any = await WebPostPreviewScene(request);
        
        if(response.status===200){
          // const blob = await response.blob()
          const blob = new Blob([response.data],{type:"video/mp4"})
          const url = await URL.createObjectURL(blob);
          newList[sceneIndex] = url;
                  
          tempFinalScenario.scenes[sceneIndex].filter = filter;
          flags[sceneIndex] = "done"
        }else{
          newList[sceneIndex] = "";
          flags[sceneIndex] = "failed"
        }
      }catch(err){

      }
    })

    try{
      const renderPromise = await new Promise(async(resolve,reject)=>{
        const renderInterval = setInterval(()=>{
          const isCompleted = flags.every(item=>item==="done");
          const hasError = flags.some(item=>item==="failed")
          if(isCompleted){
            clearInterval(renderInterval);
            resolve(true);
          }
          if(hasError){
            clearInterval(renderInterval);
            reject("Failed render");
          }
        },1000)
      })
      scenarioContext.setFinalScenario(tempFinalScenario);
      editorPageContext.setSceneVideoUrlList(newList);
    }catch(err){
      console.error(err);
    }
    setIsLoading(prev=>false)
  }



  return(
    <StyledFrame>
      {url&&
        <ImageListItem
          contentType={type}
          property1="file"
          state="default"
          fileName={scene.fileName}
          url={url}
          onAdd={undefined}
          onDelete={undefined}
        />      
      }
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>필터</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
        <Box pb={1} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            {/* <Typography variant="body2" color="secondary" sx={{ width: 60 }}>영상 필터 : </Typography> */}
            <Select
              defaultValue={"none"}
              value={filterName ||"none"}
              onChange={handleChangeFilter}
              sx={{ flex:1, height: "32px",  borderColor: "#E2E4E7" }}
              size="small"
              IconComponent={ArrowDropDownIcon}
            >
              <MenuItem value={"none"}>{t("com-pages-editor-videotabcontent.c1")}</MenuItem>
              <MenuItem value={"warm"}>{t("com-pages-editor-videotabcontent.c2")}</MenuItem>
              <MenuItem value={"cool"}>{t("com-pages-editor-videotabcontent.c3")}</MenuItem>
              <MenuItem value={"intense"}>{t("com-pages-editor-videotabcontent.c4")}</MenuItem>
            </Select>
            {/* <Typography variant="body2" color="secondary" sx={{ flex: 1 }}>{filter}</Typography> */}
          <StyledFilterBox ref={filterPreviewRef}>
            <FilterCanvas ref={originalCanvas} />
            <FilterCanvas ref={canvasRef} style={{opacity:filter.intensity}}/>
          </StyledFilterBox>
        </Box>
        <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
          <Typography variant="body2" color="secondary" sx={{ width: 40 }}>투명도</Typography>
          <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={filter.intensity*100} onChange={handleChangeOpacity}/>
          <InputStepper unitText="%" value={Math.round(filter.intensity*100)} width={140} min={0} max={100} onChange={(val:number)=>{handleChangeOpacity(undefined, val)}} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
          <Button
            $buttonType="text-s"
            $variant="brand2-subtle"
            text={"일괄적용"}
            onClick={handleClickApplyAll}
          />
          <Button
            $buttonType="text-s"
            $variant="brand1-subtle"
            text={"적용하기"}
            onClick={handleClickApply}
          />
        </Box>
        </AccordionDetails>
      </Accordion>
    </StyledFrame>
  )

      

  // return (
  //   <StyledFrame>
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel1-content"
  //         id="panel1-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption"  sx={{ flex: 1, color: 'grey.700' }}>사이즈</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //         <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <Typography variant="body2" color="secondary" sx={{ width: 40 }}>X</Typography>
  //           <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={sizeX} onChange={handleChangeX}/>
  //           <InputStepper unitText="%" value={sizeX} width={140} min={0} max={100} onChange={(val:number)=>{setSizeX(val)}} />
  //         </Box>
  //         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //           <Typography variant="body2" color="secondary" sx={{ width: 40 }}>Y</Typography>
  //           <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={sizeY} onChange={handleChangeY}/>
  //           <InputStepper unitText="%" value={sizeY} width={140} min={0} max={100} onChange={(val:number)=>{setSizeY(val)}}/>
  //         </Box>
  //       </AccordionDetails>
  //     </Accordion >
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel2-content"
  //         id="panel2-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>위치</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //         <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <LabeledInputStepper value={positionX} text="X" width={140} onChange={handleChangePositionX}/>
  //           <LabeledInputStepper value={positionY} text="Y" width={140} onChange={handleChangePositionY}/>
  //         </Box>
  //         {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <LabeledInputStepper value={degree} text="각도" width={140} onChange={(val:number)=>setDegree(val)}/>
  //           <ToggleButtons value={rotation} onChange={(val:string)=>setRotation(val)} />
  //           <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //             <IconButton>
  //             </IconButton>  
  //           </Box>
  //         </Box>     */}
  //       </AccordionDetails>
  //     </Accordion>
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel3-content"
  //         id="panel3-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>필터</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //           <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //             <Typography variant="body2" color="secondary" sx={{ width: 60 }}>영상 필터 : </Typography>
  //             <Typography variant="body2" color="secondary" sx={{ flex: 1 }}>&amp;&amp;&amp;&amp;&amp;&amp;</Typography>
  //             <StyledFilterBox />
  //           </Box>
  //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //             <Typography variant="body2" color="secondary" sx={{ width: 40 }}>투명도</Typography>
  //             <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={opacity} onChange={handleChangeOpacity}/>
  //             <InputStepper unitText="%" value={opacity} width={140} min={0} max={100} onChange={(val:number)=>{setOpacity(val)}} />
  //           </Box>
  //       </AccordionDetails>
  //     </Accordion>
  //     <Box display="flex" alignItems="center" gap={2} width="100%" flex="0 0 auto" position="relative">
  //       <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', flex: 1 }}>
  //         <Typography variant="body2" color="secondary">변환하기 </Typography>
  //         <Typography variant="caption" color="grey.400">이미지를 동영상으로, 동영상을 이미지로 </Typography>
  //       </Box>
  //       <Box display="inline-flex" height={28} alignItems="center" justifyContent="center" gap={1} pr={1} pl={1} py={0} position="relative" flex="0 0 auto" bgcolor="grey.100" borderRadius={1}>
  //         <Button variant="text" sx={{ p: 0, minWidth: 'fit-content', color: "rgba(68,75,82,1)" }}>적용</Button>
  //       </Box>
  //     </Box>
  //   </StyledFrame>
  // );
};