import React, { useState } from "react";

import styled from "styled-components";

type SizeType = "md"|"lg"

interface ToggleInterface{
  size?:SizeType,
  disabled?:boolean,
  checked?:boolean,
  setChecked?:(checked:boolean)=>void
}



function Switch({size="md", disabled=false, checked, setChecked}:ToggleInterface){
  const [on, setOn] = useState<boolean>(false);

  const handleOnClick=(e:any)=>{
    const checked = e.currentTarget.checked
    if(setChecked){
      setChecked(checked)
    }
  }

  const handleKeyDown = (e:any)=>{
    if(e.code==="Enter"){
      const checked = e.currentTarget.checked
      if(setChecked){
        setChecked(!checked)
      }
    }
  }

  return (
    <SwitchStyle
      disabled={disabled}
      width={size==="md"?30:40}
      height={size==="md"?16:20}
      role="switch"
      type="checkbox"
      checked={checked}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
    />
  )
}

export default Switch


const SwitchStyle = styled.input`
  appearance: none;
  position: relative;
  border:${({theme})=>{return (`2px solid ${theme.colors["button-gray"]}`)}};
  background-color: ${({theme})=>theme.colors["button-gray"]};
  border-radius: 9999px;
  width:${(props)=>props.width}px;
  height:${(props)=>props.height}px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  
  &::before{
    content: "";
    position: absolute;    
    width:calc(${(props)=>props.height}px - 4px);
    height:calc(${(props)=>props.height}px - 4px);
    border-radius: 50%;
    background-color: white;
  }

  &:checked{
    background-color: ${({theme})=>theme.colors["background-brand-inverse"]};
    border-color: ${({theme})=>theme.colors["background-brand-inverse"]};
  }

  &:checked::before{
      background-color: white;
      border-color: black;
      left: calc(${(props)=>props.width}px - ${(props)=>props.height}px);
  }

  &:disabled{
    opacity: 0.4;
  }
`