import React, {useState, useEffect, useRef, useContext} from 'react'
import { Box, Typography, Slider, Stack, IconButton } from "@mui/material";
import styled from "styled-components";
import { Icon29 } from "../../../../../../../Components/pages/icons/Icon29";
import { Icon166 } from "../../../../../../../Components/pages/icons/Icon166";
import { AIToggleButtons } from "../../../../../../../Components/pages/Editor/AIToggleButtons";
import { ImageListItem } from "../../../../../../../Components/pages/Editor/ImageListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { WebGetUrl, WebPostPreviewScene, WebResourceUpload } from "../../../../../../../Api/resource";
import { EditorPageContext } from "../../../../../editorPageContext";

import { useTranslation } from "react-i18next";

import { ENABLE_I2V } from '../../../../../../../Utilities';

import { WebPostInpaintingEraser, WebPostInpaintingBG, WebGetAIGernatingStatus } from "../../../../../../../Api/ai";

import { getTimeString, matchEffect } from "../../../../../../../Utilities";

import Modal from "../../../../../../../Components/common/Modal"
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";
import Button from "../../../../../../../Components/common/Button";
import { resolve } from "path";
import LoadingScreen from "../../../../../../../Components/common/LoadingScreen";
import Textarea from "../../../../../../../Components/common/Textarea";

import { ReactComponent as EraserIcon } from "../../../../../../../Assets/icon-editor/ai-eraser.svg"
import { ReactComponent as BackgroundIcon } from "../../../../../../../Assets/icon-editor/ai-background.svg"

import theme from "../../../../../../../styles/theme";
import { a } from "../../../../../../../Components/pages/ShopInfo/ImageCropper/useDebounceEffect";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";

function InpaintingModal(props){

  const {t} = useTranslation();

  const shopInfoContext = useContext(ShopInfoContext);
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);

  const {selectIndex} = editorPageContext
  const {editedFiles} = shopInfoContext
  const {finalScenario} = scenarioContext

  const [url,setUrl] = useState(undefined);
  const [isDrawing, setIsDrawing] = useState(false);
  const {isLoading, setIsLoading} = editorPageContext
  const [usePrompt, setUsePrompt] = useState(false);

  const [paths, setPaths] = useState([]);

  const [cursorSize, setCursorSize] = useState(12)
  const [cursorPos, setCursorPos] = useState({x:0, y:0});
  const mousePosRef = useRef({x:0, y:0})

  const brushElRef = useRef(null);



  const [isHover, setIsHover] = useState(false);

  const disableApplyRef = useRef(true);

  const drawingCanvasRef = useRef(null);

  useEffect(()=>{
    if(props.cmd==="genbg"){
      setUsePrompt(true)
    }else{
      setUsePrompt(false)
    }
  },[props.cmd])

  useEffect(()=>{
    console.log(finalScenario.scenes[selectIndex].layoutList[0].path||"none");
    console.log(finalScenario.scenes[selectIndex]);
    setIsLoading(prev=>false)
    initCanvas()
  },[])

  const resizeCanvas = () => {
    const canvas = drawingCanvasRef.current;
    const parent = canvas.parentElement;
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;
    console.log(`Resize canvas ${parent.clientWidth}/${parent.clientHeight}`)
  };

  useEffect(() => {
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  async function initCanvas(){
    try{
      const canvasPromise = await new Promise(async(resolve,reject)=>{
        const path = finalScenario.scenes[selectIndex].layoutList[0].path;
    
        const idx = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === finalScenario.scenes[selectIndex].fileName)
        const file = shopInfoContext.editedFiles[idx].file;
    
        const blobUrl = URL.createObjectURL(file);    

        // setUrl(blobUrl)
    
        const canvas = drawingCanvasRef.current as HTMLCanvasElement
        if(canvas){
          const ctx = canvas.getContext("2d");
          // ctx.fillStyle="#3617ce"
          // ctx.fillRect(0, 0, canvas.width, canvas.height);
          
          console.log("canvas CTX ::: ", ctx)
          // Canvas 크기 설정
          // canvas.width = 500;
          // canvas.height = 500;
          // 이미지 불러오기
          const image = new Image();
          image.src = blobUrl; // 사용할 이미지 경로
          image.onload = () => {
            const height = image.naturalHeight;
            const width = image.naturalWidth;
            console.log("load image :: ", image.naturalWidth);
            // 이미지를 캔버스에 그리기
            canvas.width = width;
            canvas.height= height;
            ctx.drawImage(image, 0, 0, width, height);
            resolve(true)
          };
          image.onerror = (error) => {
            reject(`Image load Error :: ", error`)
          }
        }else{
          reject(`canvas element error`)
        }
      })
      return canvasPromise;
    }catch(err){
      console.error(err);
      return false
    }
  }

  // 마우스 드로잉 시작
  const startDrawing = (event) => {
    const canvas = drawingCanvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width; // 너비 비율
    const scaleY = canvas.height / rect.height; // 높이 비율

    // 변환된 좌표
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    const { offsetX, offsetY } = event.nativeEvent;
    ctx.moveTo(x, y);
    setIsDrawing(true);

    setPaths((prevPaths) => [
      ...prevPaths,
      { points: [{ x, y }], color: "#3617ce", width: cursorSize },
    ]);
  };

  // 마우스 움직임에 따라 그림 그리기
  const draw = (event) => {
    event.stopPropagation();

    const brushEl = brushElRef.current as HTMLDivElement;
    if(brushEl){
      // console.log(`moving brush (${event.clientX.toFixed(0)}, ${event.clientY.toFixed(0)})`)
      brushEl.style.top = `${event.clientY}.px`
      brushEl.style.left = `${event.clientX}.px`
    }

    if (!isDrawing) {
      return
    };

    
    const canvas = drawingCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width; // 너비 비율
    const scaleY = canvas.height / rect.height; // 높이 비율

    // console.log(`${scaleX} = ${canvas.width} / ${rect.width}`)
    // console.log(`${scaleY} = ${canvas.height} / ${rect.height}`)

    // 변환된 좌표
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    const { offsetX, offsetY } = event.nativeEvent;

    ctx.globalCompositeOperation = "destination-out"; // 투명 영역 설정

    ctx.lineTo(x, y);
    // ctx.strokeStyle = '#FFFFFF'; // 선 색상
    // ctx.lineCap = "round"
    // ctx.lineWidth = 14; // 선 굵기
    // ctx.stroke();    
    
    ctx.strokeStyle = '#3617ce'; // 선 색상
    ctx.lineCap = "round"
    ctx.lineJoin = "round";
    ctx.lineWidth = cursorSize; // 선 굵기
    ctx.stroke();

    ctx.globalCompositeOperation = "source-over";


    setPaths((prevPaths) => {
      try{
        const newPaths = [...prevPaths];
        let currentPath = newPaths[newPaths.length - 1];
        // currentPath.points=[];
        currentPath.points.push({ x, y });
        return newPaths;
      }catch(err){
        console.error("failed to set path ::: ", err)
      }
    });

    // ctx.globalCompositeOperation = "source-over";
  };
  useEffect(()=>{
    // console.log("canvas paths :: ", paths)
    if(paths.length===0){
      disableApplyRef.current = true;
    }else{
      paths.forEach(path=>{
        if(path.points.length===1){
          disableApplyRef.current = true
        }else{
          disableApplyRef.current = (false&&disableApplyRef.current)
        }
      })
    }
  },[paths])

  // 마우스 드로잉 종료
  const stopDrawing = () => {
    // console.log("stop drawing")
    const canvas = drawingCanvasRef.current;
    const ctx = canvas.getContext('2d');
    // console.log("canvas CTX ::: ", ctx)
    ctx.closePath();
    // mergeStrokes();
    setIsHover(false);
    setIsDrawing(false);
  };

  const mergeStrokes = () => {
    if (paths.length === 0) return;

    // 모든 경로를 하나로 합침
    const mergedPoints = paths.flatMap((path) => path.points);

    const mergedPath = [
      {
        points: mergedPoints,
        color: "#3617ce", // 병합 후의 색상
        width: cursorSize, // 병합 후의 선 굵기
      },
    ];
    console.log('path merged')
    // 기존 경로를 병합된 하나의 경로로 교체
    setPaths(prev=>mergedPath);
    redrawCanvas(mergedPath);
  };

  const redrawCanvas = async  (mergedPath = null) => {

    await initCanvas();

    const canvas = drawingCanvasRef.current;
    const ctx = canvas.getContext("2d");


    // 캔버스 초기화
    // ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 기존 경로 다시 그리기 (혹은 합쳐진 경로만 그리기)
    const drawPath = mergedPath || paths;
    ctx.globalCompositeOperation = "destination-out"; // 투명 영역 설정
    drawPath.forEach(({ points, color, width }) => {
      if (points.length < 2) return;

      // ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);

      ctx.lineWidth = width;
      ctx.strokeStyle = color;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";

      points.forEach(({ x, y }) => ctx.lineTo(x, y));
      ctx.stroke();

    });
    ctx.globalCompositeOperation = "source-over";

  };


  const handleUploadAndErase = async ()=>{
    const canvasEl = drawingCanvasRef.current as HTMLCanvasElement;
    if(canvasEl){
      setIsLoading(prev=>true)
      
      const stop =true;

      // await redrawCanvas();

      
      // const blob = canvasEl.toBlob("image/png");      
      try{
        let fileBlob = undefined;
        const idx = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === finalScenario.scenes[selectIndex].fileName)
        const file = shopInfoContext.editedFiles[idx].file;
        const fileExt = '.'+file.name.split('.')[file.name.split('.').length-1]
        const fileName = file.name.replace(fileExt,"");
        const maskedFileName = usePrompt? fileName+"_genbg_"+getTimeString()+fileExt : fileName+"_eraser_"+getTimeString()+fileExt
        
        const blobPromise = await new Promise((resolve, reject)=>{
          canvasEl.toBlob(
            async (blob) => {
              console.log('blob :: ', blob)
              if (blob) {
                // Blob을 다운로드 링크로 생성
                const file = await new File([blob], maskedFileName, { type: blob.type });
                resolve(file)
              }else{
                reject('failed to make blob file')
              }
            },
            "image/png", // 포맷: "image/png" 또는 "image/jpeg"
          );
        })   
        fileBlob = blobPromise;
        console.log("File :: ", fileBlob)

        
        // const blobUrl = URL.createObjectURL(fileBlob);
        // const aEl = document.createElement("a") as HTMLAnchorElement;
        // aEl.href = blobUrl;
        // aEl.download = "downloaded_file.png"
        // aEl.click();
        
        // if(stop){
        //   setIsLoading(prev=>false)
        //   return
        // }

        const assetId = sessionStorage.getItem("ASSET_ID");

        const uploadFormData = new FormData();
        uploadFormData.append('assetId', assetId);
        uploadFormData.append('mainCategory', "image");
        uploadFormData.append('subCategory', "original");
        uploadFormData.append('file', fileBlob);
        
        const uploadResponse:any = await WebResourceUpload(uploadFormData);
  
        if(uploadResponse.result!==0){
          throw Error(`Upload failed ::: ${uploadResponse.errMsg}`)
        }

        const timeString = getTimeString();

        const inpaintAssetId = `${assetId}_${timeString}`;
        const inpaintingRequest = {
          assetId: inpaintAssetId,
          cmd: props.cmd,
          guide_prompt: usePrompt? prompt :"background",
          input_file: uploadResponse.resourceInfo.s3key,
          output_file:`t2i_client/${assetId}/${fileName}_${timeString}`,
          seed:"-1",
          width: 1920,
          height: 1080,
        }
        let inpaintResponse:any
        if(usePrompt){
          inpaintResponse = await WebPostInpaintingBG(inpaintingRequest)
        }else{
          inpaintResponse = await WebPostInpaintingEraser(inpaintingRequest)
        }

        if(inpaintResponse.result !== 0 ){
          throw Error(`inpaint request failed ::: ${inpaintResponse.errMsg}`)
        }

        let failCount = 0;

        const inpaintingPromise:any = await new Promise(async(resolve, reject)=>{
          const inpaintingInterval = setInterval(async()=>{
            const request = {
              assetId: inpaintAssetId,
              moduleType: props.cmd
            }
            const response = await WebGetAIGernatingStatus(request);
            if(response.result !== 0 ){
              clearInterval(inpaintingInterval)
              reject(response.errMsg)
            }

            switch(response.state){
              case "ready":
                console.log("inpainting ready");
                break;
              case "running":
                console.log("inpainting running ::: ", response.progress)
                break;
              case "succeeded":
                clearInterval(inpaintingInterval)
                console.log("inpainting succeeded");
                resolve(response)          
                break;
            }
          }, 2000)
        })

        const resultFileKeys = inpaintingPromise.output_list;
        const presignedList = [];
        const inpaintingList = [];
        for(let i=0; i<resultFileKeys.length; i++){
          
          const response = await WebGetUrl({
            assetId: sessionStorage.getItem("ASSET_ID"),
            bucket: inpaintingPromise.bucket,
            key: resultFileKeys[i]
          })
          if(response.result===0){
            console.log(`${resultFileKeys[i]} ==> ${response.presignedUrl}`)

            const object = {
              s3Key: resultFileKeys[i],
              bucket: inpaintingPromise.bucket,
              presignedUrl:response.presignedUrl
            }

            inpaintingList.push(object);
          }else{
            inpaintingList.push(undefined)
          }
        }

        if(props.cmd==="remobj"){
          props.setEraserList(prev=>[...prev, ...inpaintingList])
        }else if(props.cmd==="genbg"){
          props.setGenBgList(prev=>[...prev, ...inpaintingList])
        }
      }catch(err){
        console.error(err);
      }
    }
    if(props.handleClose){
      props.handleClose();
    }
    setIsLoading(prev=>false)
  }

  const [prompt, setPrompt] = useState('');

  const handleChangePrompt = (e) => {
    setPrompt(e.target.value)
  }

  const handleChangeSize = (e)=>{
    setCursorSize(e.target.value)
  }

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   if(canvas){
  //     const context = canvas.getContext("2d");
  //     const draw = () => {
  //       context.beginPath();
  //       context.arc(cursorPos.x, cursorPos.y, 10, 0, Math.PI * 2); // Draw red circle
  //       context.fillStyle = "red";
  //       context.stroke();
  //       // context.fill();
  //       context.closePath();
  //     };  
  //     draw();
  //   }
  // }, [cursorPos]);

  const mouseDoNothing = (event)=>{
    event.preventDafault();
  }

  const handleClearDraw = async () => {
    setPaths([]);
    await initCanvas()
  }
  const handleClickCancel = () => {
    if(props.handleClose){
      props.handleClose();
    }
  }

  const handleMouseEnterCanvas = () => {
    setIsHover(true)
  }

  return(
    <Modal {...props} style={{zIndex:1000}}>
      <ModalContainer>
        <ModalHead>
          <div>{props.cmd==="remobj"?"AI 지우개":"AI 배경생성"}
          </div>
          <Button $buttonType="text-l" $variant="beige" text="초기화" onClick={handleClearDraw}/>
        </ModalHead>
        <ModalContent>
          <CanvasArea
            ref={drawingCanvasRef}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseEnter={handleMouseEnterCanvas}
            onMouseLeave={stopDrawing} // 캔버스 밖으로 나갈 때 드로잉 종료
          />
          {
            isHover&&
            <BrushStyle
              ref={brushElRef}
            />
          }
          <Stack gap={1} direction="column" sx={{width: '100%', padding:`${theme.spacing['spacing-04']} ${theme.spacing['spacing-08']}` }}>
            <Typography variant="caption"  sx={{ color: 'grey.700' }}>브러시 크기</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
              <Slider sx={{ flex: 1, marginLeft: 1}} color="primary" size="small" defaultValue={0} value={cursorSize} onChange={handleChangeSize} min={1} max={300}/>
              <Typography variant="body2" color="#17191C" sx={{ width: 24, textAlign: 'right' }}>{cursorSize}</Typography>
            </Box>        
            {
              usePrompt && 
              <>
              <Typography variant="caption"  sx={{ color: 'grey.700' }}>생성 내용</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                <Textarea value={prompt} style={{width:"100%", maxWidth:"100%", resize:'none'}} onChange={handleChangePrompt} placeholder={t("com-pages-editor-inpaintingmodal.p1")}></Textarea>            
              </Box>        
              </>
            }
          </Stack>
        </ModalContent>
        <ModalFoot>
          <Button style={{width:"40%"}} $buttonType="capsule" $variant="brand1-subtle" text="취소" onClick={handleClickCancel}/>
          <Button disabled={disableApplyRef.current} style={{width:"40%"}} $buttonType="capsule" $variant="brand1" text="적용" onClick={handleUploadAndErase}/>
        </ModalFoot>
      </ModalContainer>
    </Modal>
  )
}

export default InpaintingModal

const ModalContainer = styled.div`
  position:relative;
  width: 900px;
  height: fit-content;
  user-select: none;
`

const ModalHead = styled.div`
  position: relative;
  width: 100%;
  height: fit-content; 
  display: flex;

  padding: ${(props)=>props.theme.spacing["spacing-11"]};
  ${(props)=>props.theme.typography["pre-heading-04"]};
  justify-content: space-between;
`
const ModalContent = styled.div`
  position: relative;
  padding: ${props=>props.theme.spacing["spacing-04"]} 0px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;

  width:100%;

`
const ModalFoot = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: ${props=>props.theme.spacing["spacing-09"]} ${props=>props.theme.spacing["spacing-19"]};
  gap: ${props=>props.theme.spacing["spacing-08"]};
  width:100%; 
`

const CanvasArea = styled.canvas`
  background-color: #3617ce;
  // width:100%;
  height: 500px;
  overflow: hidden;

  cursor: crosshair
`

const BrushStyle = styled.div`
  position: fixed;
  border: 1px solid red;
  border-radius: 9999px;
  background-color:transparent;
  z-index:999
`

const LoadingScreenWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.4);
  display:flex;

  justify-content: center;
  align-items:center;
  
`