import React, { useEffect, useRef, useState } from "react";

import { CheckButtonStyle,CheckButtonIconStyle,CheckButtonTextStyle, CheckIconStyle } from "./CheckButton.styled";

import {ReactComponent as CameraIcon} from "../../../Assets/IconCheckBtn/camera.svg"
import {ReactComponent as CarrotIcon} from "../../../Assets/IconCheckBtn/carrot.svg"
import {ReactComponent as CleanIcon} from "../../../Assets/IconCheckBtn/clean.svg"
import {ReactComponent as DumbelIcon} from "../../../Assets/IconCheckBtn/dumbel.svg"
import {ReactComponent as ForkIcon} from "../../../Assets/IconCheckBtn/fork.svg"
import {ReactComponent as FriendsIcon} from "../../../Assets/IconCheckBtn/friends.svg"
import {ReactComponent as GenderFemaleIcon} from "../../../Assets/IconCheckBtn/GenderFemale.svg"
import {ReactComponent as LocationIcon} from "../../../Assets/IconCheckBtn/location.svg"
import {ReactComponent as MenuIcon} from "../../../Assets/IconCheckBtn/menu.svg"
import {ReactComponent as PencilIcon} from "../../../Assets/IconCheckBtn/pencil.svg"

import {ReactComponent as CheckIcon} from "../../../Assets/Icons/Check.svg"
import theme from "../../../styles/theme";


type CheckButtonIconTypes= "camera"|"carrot"|"clean"|"dumbel"|"fork"|"friends"|"GenderFemale"|"location"|"menu"|"pencil"

function CheckButton(props){

  const [icon, setIcon] = useState(<></>);

  const buttonRef = useRef(null);

  const svgStyleProps = {width: "24px", height: "24px"};

  useEffect(()=>{
    switch(props.icon as CheckButtonIconTypes){
      case "camera":
        setIcon(<CameraIcon style={svgStyleProps}/>)
        break;
      case "carrot":
        setIcon(<CarrotIcon style={svgStyleProps}/>);
        break;
      case "clean":
        setIcon(<CleanIcon style={svgStyleProps}/>);
        break;
      case "dumbel":
        setIcon(<DumbelIcon style={svgStyleProps}/>);
        break;
      case "fork":
        setIcon(<ForkIcon style={svgStyleProps}/>);
        break;
      case "friends":
        setIcon(<FriendsIcon style={svgStyleProps}/>);
        break;
      case "GenderFemale":
        setIcon(<GenderFemaleIcon style={svgStyleProps}/>);
        break;
      case "location":
        setIcon(<LocationIcon style={svgStyleProps}/>);
        break;
      case "menu":
        setIcon(<MenuIcon style={svgStyleProps}/>);
        break;
      case "pencil":
        setIcon(<PencilIcon style={svgStyleProps}/>);
        break;
    }
  },[props.icon])

  useEffect(()=>{
    const element = (buttonRef.current as HTMLElement)
    if(element){
      if(props.checked){
        if(!element.classList.contains("checked")){
          element.classList.add("checked")
        }
      }else{
        if(element.classList.contains("checked")){
          element.classList.remove("checked")
        }
      }
    }
  },[props.checked])

  const handleMouseLeave = () => {
    const el = buttonRef.current as HTMLButtonElement;
    if(el){
      el.blur();
    }
  }

  return(
    <CheckButtonStyle ref={buttonRef} onMouseLeave={handleMouseLeave} {...props}>
      {/* <CheckButtonIconStyle>
        {icon}
      </CheckButtonIconStyle> */}
      <CheckButtonTextStyle>
        {props.text}
      </CheckButtonTextStyle>
      {props.checked && <CheckIconStyle/>}
    </CheckButtonStyle>
  )
}

export default CheckButton