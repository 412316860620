import styled from "styled-components"
import Button from "../Button";

export const InputTextGroupStyle = styled.div`
  align-items: center;
  display:flex;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  position: relative;
  width: 100%;
  ${(props)=>props.theme.typography["pre-body-05"]};
`

export const InputStyle = styled.div`
  position: relative;
  display: flex;
  align-items:center;
  justify-contents: space-between;
  flex:1;
`
export const InputTextGroupButtonStyle = styled(Button)`  
  // cursor:pointer;
  position:absolute;
  right:8px;
`