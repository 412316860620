import { Box, Divider, IconButton, Slider } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { ReactComponent as UndoIcon } from "../../../../../Assets/icon-editor/timeline-undo.svg";
import { ReactComponent as RedoIcon } from "../../../../../Assets/icon-editor/timeline-redo.svg";
import { ReactComponent as DeleteIcon } from "../../../../../Assets/icon-editor/timeline-delete.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../../Assets/icon-editor/timeline-left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../Assets/icon-editor/timeline-right-arrow.svg";
import { ReactComponent as CutIcon } from "../../../../../Assets/icon-editor/timeline-cut.svg";
import { ReactComponent as CropIcon } from "../../../../../Assets/icon-editor/timeline-crop.svg";
import { ReactComponent as ZoomInIcon } from "../../../../../Assets/icon-editor/timeline-zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../../../../Assets/icon-editor/timeline-zoom-out.svg";
import { EditorPageContext } from "../../../editorPageContext";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { EditorContext } from "../../../../../Contexts/EditorContext";
import { ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";

import transitionEffectData from "../../../../../Data/screenEffect.json"
import LoadingScreen from "../../../../../Components/common/LoadingScreen";

import { WebPostPreviewScene } from "../../../../../Api/resource";
import { matchEffect } from "../../../../../Utilities";

interface Props {
  duration: number;
  onChangeMagnify?:Function
}

export const TimelineHeader = ({ duration, onChangeMagnify=undefined }: Props): JSX.Element => {
  const [magnifyValue, setMagnifyValue] = useState(3);
  
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)

  const {isLoading, setIsLoading} = editorPageContext;

  useEffect(()=>{
    if(onChangeMagnify){
      onChangeMagnify(magnifyValue)
    }
  },[magnifyValue])

  const handleChangeMagnify = (event: Event, newValue: number ) => {
    console.log("mag :: ", newValue)
    setMagnifyValue(newValue);
  };

  const handleIncreaseMaginfy = () =>{
    setMagnifyValue(prevVal => ( prevVal + 1 > 6 ? 6 : prevVal + 1))
  }

  const handleDecreaseMagnify = () =>{
    setMagnifyValue(prevValue => (prevValue > 0 ? prevValue - 1 : 0)); // 0 이하로는 감소하지 않도록
  }


  async function renderScene({finalScenario, effectList, sceneIndex, urlList}){
    const currentScene = finalScenario.scenes[sceneIndex];
    const nextScene = finalScenario.scenes[sceneIndex+1]

    const sceneRequest = {
      assetId: sessionStorage.getItem("ASSET_ID"),
      sceneNo: sceneIndex,
      screenBefore: currentScene.layoutList[0].path,
      screenAfter: nextScene.layoutList[0].path,
      screenBeforeEffect: effectList[sceneIndex].before,
      screenAfterEffect: effectList[sceneIndex].after,
      effect: matchEffect(currentScene.effect),
      duration: currentScene.time,
      durationAfter: nextScene? nextScene.time : 3,
      mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
      serverType: process.env.REACT_APP_SERVER_TYPE,
      inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
      outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
      shopName: shopInfoContext.shopName,
      isDev: false,
      languageCode:shopInfoContext.language
    }

    const fileResponse:any = await WebPostPreviewScene(sceneRequest);
    if(fileResponse.status===200){
      const blob = new Blob([fileResponse.data],{type:"video/mp4"})
      const url = await URL.createObjectURL(blob);
      
      let newList = JSON.parse(JSON.stringify(urlList))
      newList[sceneIndex] = url
      return newList
    }else{
      return urlList
      // textUrlList.push('');
    }
  }


  const handleSwitchLeft = async () => {
    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario))
    const index = editorPageContext.selectIndex;

    if(index===undefined){return}

    const type = editorPageContext.selectType;

    console.log(`type ${editorPageContext.selectType} / index ${editorPageContext.selectIndex}`)

    const prevIndex = editorPageContext.selectIndex-1

    switch(type){
      case "video":{
        setIsLoading(prev=>true)
        
        ////////////////// 파일 교체 //////////////////////////
        const textList = finalScenario.scenes[index].textList;
        const narrationInfo = finalScenario.scenes[index].narrationInfo;
        const type = finalScenario.scenes[index].type
        const sceneNo = finalScenario.scenes[index].no
        const time = finalScenario.scenes[index].time

        const altTextList = finalScenario.scenes[index-1].textList
        const altNarrationInfo = finalScenario.scenes[index-1].narrationInfo;
        const altType = finalScenario.scenes[index-1].type
        const altSceneNo = finalScenario.scenes[index-1].no
        const altTime = finalScenario.scenes[index-1].time

        const scene = finalScenario.scenes[index];
        finalScenario.scenes[index] = finalScenario.scenes[index-1]
        finalScenario.scenes[index-1] = scene

        finalScenario.scenes[index].textList = textList;
        finalScenario.scenes[index].narrationInfo = narrationInfo;
        finalScenario.scenes[index].no = sceneNo
        finalScenario.scenes[index].time = time

        finalScenario.scenes[index-1].textList = altTextList;
        finalScenario.scenes[index-1].narrationInfo = altNarrationInfo;
        finalScenario.scenes[index-1].no = altSceneNo
        finalScenario.scenes[index-1].time = altTime

        ////////////////// 화면효과 교체 //////////////////////////
        let effectList = JSON.parse(JSON.stringify(editorContext.screenEffectList));
        const currentEffect = effectList[index].before

        effectList[index].before = effectList[index-1].before

        effectList[index-1].after = effectList[index-1].before
        effectList[index-1].before = currentEffect

        if(effectList[index-2]){
          effectList[index-2].after=currentEffect;
        }
        editorContext.setScreenEffectList(effectList);

        ////////////////// AI 생성 이미지인 경우 생성이미지 데이터 교체 //////////////////////////
        if(type==="AI 생성 이미지"){
          console.log(scenarioContext.generatedImages);
          let genImages = JSON.parse(JSON.stringify(scenarioContext.generatedImages))

          const genImageIndex = genImages.findIndex(genImage=>genImage.sceneNo===index+1);
          genImages[genImageIndex].sceneNo = index;
          scenarioContext.setGeneratedImages(genImages)
        } else if(altType==="AI 생성 이미지"){
          console.log(scenarioContext.generatedImages);
          let genImages = JSON.parse(JSON.stringify(scenarioContext.generatedImages))

          const genImageIndex = genImages.findIndex(genImage=>genImage.sceneNo===index);
          genImages[genImageIndex].sceneNo = index+1
          scenarioContext.setGeneratedImages(genImages)
        }

        const urlList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList));
        const list1 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index-1, urlList:urlList})
        const list2 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index, urlList : list1})
        const list3 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index+1, urlList: list2})

        editorPageContext.setSelectIndex(prevIndex)
        editorPageContext.setSceneVideoUrlList(list3)
        setIsLoading(prev=>false)
        break;
      }
      case "text":{
        editorPageContext.setSelectIndex(prevIndex)
        const textList = finalScenario.scenes[index].textList;        
        finalScenario.scenes[index].textList = finalScenario.scenes[index-1].textList
        finalScenario.scenes[index-1].textList = textList; 

        let urlList =JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
        const copy = urlList[index];
        urlList[index] = urlList[index-1]
        urlList[index-1] = copy;
        editorPageContext.setTextVideoUrlList(urlList)
        break;
      }
      case "ntr":{
        // let actorList = JSON.parse(JSON.stringify(scenarioContext.editedTTSActorList));
        // const actor = actorList[index]
        // actorList[index] = actorList[index-1]
        // actorList[index-1] = actor;
        // scenarioContext.setEditedTTSActorList(actorList);

        // let ttsVolumeList = JSON.parse(JSON.stringify(editorContext.ttsVolumeList))
        // const volume = ttsVolumeList[index];
        // ttsVolumeList[index] = ttsVolumeList[index-1];
        // ttsVolumeList[index-1] = volume
        // editorContext.setTtsVolumeList(ttsVolumeList);

        // let ttsUrlList = JSON.parse(JSON.stringify(editorPageContext.ntrUrlList));
        // const url = ttsUrlList[index];
        // ttsUrlList[index] = ttsUrlList[index-1];
        // ttsUrlList[index-1] = url;
        // editorPageContext.setNtrUrlList(ttsUrlList)


        // let narrationInfo = finalScenario.scenes[index].narrationInfo;
        // finalScenario.scenes[index].narrationInfo = finalScenario.scenes[index-1].narrationInfo;
        // finalScenario.scenes[index-1].narrationInfo = narrationInfo;
        
        break;
      }
    }
    scenarioContext.setFinalScenario(finalScenario);    
  } 


  const handleSwitchRight = async () => {
    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario))
    const index = editorPageContext.selectIndex;

    if(index===undefined){return;}

    const type = editorPageContext.selectType;
    const alterType = finalScenario.scenes[index+1].type

    console.log(`type ${editorPageContext.selectType} / index ${editorPageContext.selectIndex}`)

    const nextIndex = editorPageContext.selectIndex+1;

    switch(type){
      case "video":{
        setIsLoading(prev=>true)
        ////////////////// 파일 교체 //////////////////////////
        const textList = finalScenario.scenes[index].textList;
        const narrationInfo = finalScenario.scenes[index].narrationInfo;
        const type = finalScenario.scenes[index].type
        const sceneNo = finalScenario.scenes[index].no
        const time = finalScenario.scenes[index].time

        const altTextList = finalScenario.scenes[index+1].textList
        const altNarrationInfo = finalScenario.scenes[index+1].narrationInfo;
        const altType = finalScenario.scenes[index+1].type
        const altSceneNo = finalScenario.scenes[index+1].no
        const altTime = finalScenario.scenes[index+1].time

        const scene = finalScenario.scenes[index];
        finalScenario.scenes[index] = finalScenario.scenes[index+1]
        finalScenario.scenes[index+1] = scene

        finalScenario.scenes[index].textList = textList;
        finalScenario.scenes[index].narrationInfo = narrationInfo;
        finalScenario.scenes[index].no = sceneNo
        finalScenario.scenes[index].time = time

        finalScenario.scenes[index+1].textList = altTextList;
        finalScenario.scenes[index+1].narrationInfo = altNarrationInfo;
        finalScenario.scenes[index+1].no = altSceneNo
        finalScenario.scenes[index+1].time = altTime
        ////////////////// 화면효과 교체 //////////////////////////
        let effectList = JSON.parse(JSON.stringify(editorContext.screenEffectList));
        const currentEffect = effectList[index].before

        effectList[index].before = effectList[index+1].before;
        if(effectList[index-1]){
          effectList[index-1].after = effectList[index+1].before
        }

        effectList[index].after = currentEffect
        effectList[index+1].before = currentEffect

        editorContext.setScreenEffectList(effectList);

        ////////////////// AI 생성 이미지인 경우 생성이미지 데이터 교체 //////////////////////////
        if(type==="AI 생성 이미지"){
          console.log(scenarioContext.generatedImages);
          let genImages = JSON.parse(JSON.stringify(scenarioContext.generatedImages))

          const genImageIndex = genImages.findIndex(genImage=>genImage.sceneNo===index+1);
          genImages[genImageIndex].sceneNo = index+2;
          scenarioContext.setGeneratedImages(genImages)
        } else if(alterType==="AI 생성 이미지"){
          console.log(scenarioContext.generatedImages);
          let genImages = JSON.parse(JSON.stringify(scenarioContext.generatedImages))

          const genImageIndex = genImages.findIndex(genImage=>genImage.sceneNo===index+2);
          genImages[genImageIndex].sceneNo = index+1
          scenarioContext.setGeneratedImages(genImages)
        }

        const urlList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList));
        let list1=urlList
        if(index!=0){
          list1 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index-1, urlList:urlList})
        }
        const list2 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index, urlList : list1})
        const list3 = await renderScene({finalScenario:finalScenario, effectList:effectList, sceneIndex:index+1, urlList: list2})

        editorPageContext.setSceneVideoUrlList(list3)
        editorPageContext.setSelectIndex(nextIndex)
        setIsLoading(prev=>false)
        break;
      }
      case "text":{
        editorPageContext.setSelectIndex(nextIndex)
        const textList = finalScenario.scenes[index].textList;        
        finalScenario.scenes[index].textList = finalScenario.scenes[index+1].textList
        finalScenario.scenes[index+1].textList = textList; 

        let urlList =JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
        const copy = urlList[index];
        urlList[index] = urlList[index+1]
        urlList[index+1] = copy;
        editorPageContext.setTextVideoUrlList(urlList)

        break;
      }
      case "ntr":{
        // let actorList = JSON.parse(JSON.stringify(scenarioContext.editedTTSActorList));
        // const actor = actorList[index]
        // actorList[index] = actorList[index+1]
        // actorList[index+1] = actor;
        // scenarioContext.setEditedTTSActorList(actorList);

        // let ttsVolumeList = JSON.parse(JSON.stringify(editorContext.ttsVolumeList))
        // const volume = ttsVolumeList[index];
        // ttsVolumeList[index] = ttsVolumeList[index+1];
        // ttsVolumeList[index+1] = volume
        // editorContext.setTtsVolumeList(ttsVolumeList);

        // let ttsUrlList = JSON.parse(JSON.stringify(editorPageContext.ntrUrlList));
        // const url = ttsUrlList[index];
        // ttsUrlList[index] = ttsUrlList[index+1];
        // ttsUrlList[index+1] = url;
        // editorPageContext.setNtrUrlList(ttsUrlList)

        // let narrationInfo = finalScenario.scenes[index].narrationInfo;
        // finalScenario.scenes[index].narrationInfo = finalScenario.scenes[index+1].narrationInfo;
        // finalScenario.scenes[index+1].narrationInfo = narrationInfo;
        
        break;
      }
    }

    scenarioContext.setFinalScenario(finalScenario);
  }

  const [disableLeft, setDisableLeft] = useState(false);
  const [disableRight, setDisableRight] = useState(false);

  useEffect(()=>{
    if(editorPageContext.selectType){
      if(editorPageContext.selectType==="bgm" || editorPageContext.selectType==="ntr"){
        setDisableLeft(true)
        setDisableRight(true)
      }else{
        if(editorPageContext.selectIndex!==undefined){
          if(editorPageContext.selectIndex===0 || editorPageContext.selectIndex===scenarioContext.finalScenario.scenes.length-1){
            setDisableLeft(true);
          }else{
            setDisableLeft(false);
          }
    
          if(editorPageContext.selectIndex>=scenarioContext.finalScenario.scenes.length-2){
            setDisableRight(true)
          }else{
            setDisableRight(false)
          }
        }else{
          setDisableLeft(true)
          setDisableRight(true)
        }
      }
    }else{
      setDisableLeft(true)
      setDisableRight(true)
    }
  },[editorPageContext.selectType,editorPageContext.selectIndex])


  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" height="40px" sx={{position:"relative", zIndex:999}}>
      <Box display="flex" alignItems="center" gap={1} paddingLeft={2}>
        <IconButton
          onClick={handleSwitchLeft}
          size="small"
          sx={{opacity:(disableLeft?0.5:1)}}
          disabled={disableLeft}
        >
          <LeftArrowIcon width={16} height={16} />
        </IconButton>
        <IconButton
          onClick={handleSwitchRight}
          size="small"
          sx={{opacity:(disableRight?0.5:1)}}
          disabled={disableRight}
        >
          <RightArrowIcon width={16} height={16} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" gap={1} paddingRight={2}>
        <IconButton size="small" onClick={handleDecreaseMagnify}>
          <ZoomInIcon width={16} height={16} />
        </IconButton>
        <Slider value={magnifyValue} defaultValue={2} size="small" marks step={1} min={0} max={6} sx={{ width:"80px" }} onChange={handleChangeMagnify}></Slider>
        <IconButton size="small" onClick={handleIncreaseMaginfy}>
          <ZoomOutIcon width={16} height={16} />
        </IconButton>
      </Box>
    </Box>

  )
}