import React from "react";
import styled from "styled-components";
import InputText from "../InputText";
import InputLabel from "../InputLabel";

import {InputTextGroupStyle, InputStyle, InputTextGroupButtonStyle} from './InpuTextGroup.styled'

import Dot from "../Dot";
import Button from "../Button";

function InputTextGroup(props){

  const handleOnClickButton = (e:any) => {
    if(props.onClickButton){
      props.onClickButton();
    }
  }

  return (
    <InputTextGroupStyle>
      {props.showLabel &&
        <InputLabel size="sm" style={{width:"80px"}} label={props.label} required={props.required}/>
      }
      <InputStyle>
        <InputText style={{flex:1}} {...props} value={props.value}/>
        {props.showButton&&
          <Button
            style={{position:"absolute",right:"8px"}}
            onClick={handleOnClickButton}
            $buttonType="box"
            $variant="brand2-subtle"
            text={props.buttonLabel}
          />
        }       
      </InputStyle>
    </InputTextGroupStyle>
  )
}

export default InputTextGroup
