import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as PlayIcon } from "../../../../../Assets/icon-player/Play.svg";
import { ReactComponent as PauseIcon } from "../../../../../Assets/icon-player/Pause.svg";
import { ReactComponent as BackIcon } from "../../../../../Assets/icon-player/ArrowCounterClockwise.svg";
import { ReactComponent as FwdIcon } from "../../../../../Assets/icon-player/ArrowClockwise.svg";
import { ReactComponent as VolumeIcon } from "../../../../../Assets/icon-player/SpeakerSimpleHigh.svg";
import { ReactComponent as MuteIcon } from "../../../../../Assets/icon-player/SpeakerSimpleSlash.svg";

function PlayerIcon(props){
  const [icon, setIcon] = useState(undefined);
  useEffect(()=>{
    if(props.icon){
      switch(props.icon){
        case "play":
          setIcon(<PlayIcon {...props}/>)
          break;
        case "pause":
          setIcon(<PauseIcon {...props}/>)
          break;
        case "back":
          setIcon(<BackIcon {...props}/>)
          break;
        case "forward":
          setIcon(<FwdIcon {...props}/>)
          break;
        case "volume":
          setIcon(<VolumeIcon {...props}/>)
          break;
        case "mute":
          setIcon(<MuteIcon {...props}/>)
          break;
      }
    }
  },[props.icon])

  return(icon)
}

export default PlayerIcon

const PlayerIconStyle = styled(PlayerIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`
