const basic_colors = {
  /* GREY SCALE(ROOT) */
  "grey-white": "#ffffff",
  "grey-50": "#f0f2f3",
  "grey-100": "#e2e4e7",
  "grey-200": "#c6cbcf",
  "grey-300": "#a9b0b7",
  "grey-400": "#8d969f",
  "grey-500": "#717d87",
  "grey-600": "#5a636c",
  "grey-700": "#444b52",
  "grey-800": "#2d3236",
  "grey-900": "#17191c",
  "grey-transparent-200": "#00000033",
  "grey-transparent-500": "#00000080",

  /* GREEN SCALE(ROOT) */
  "green-50": "#e0ffeb",
  "green-100": "#bef4d0",
  "green-200": "#7ee29f",
  "green-300": "#4bce77",
  "green-400": "#2abb5a",
  "green-500": "#22a04c",
  "green-600": "#1f8441",
  "green-700": "#216e3b",
  "green-800": "#174f2a",
  "green-900": "#16281c",

  /* YELLOW SCALE(ROOT) */
  "yellow-50": "#fef7d7",
  "yellow-100": "#fcf1b5",
  "yellow-200": "#fbe574",
  "yellow-300": "#f9d414",
  "yellow-400": "#efb806",
  "yellow-500": "#d7a204",
  "yellow-600": "#a67d03",
  "yellow-700": "#896601",
  "yellow-800": "#644c02",
  "yellow-900": "#322601",

  /* RED SCALE(ROOT) */
  "red-50": "#fff1f0",
  "red-100": "#ffd7d2",
  "red-200": "#fd9e91",
  "red-300": "#f87968",
  "red-400": "#f16350",
  "red-500": "#e2483d",
  "red-600": "#c93e2c",
  "red-700": "#ae3424",
  "red-800": "#782b21",
  "red-900": "#510e16",

  /* PINK SCALE(ROOT) */
  "pink-50": "#fff0f1",
  "pink-100": "#fdd0d5",
  "pink-200": "#f995a0",
  "pink-300": "#f76476",
  "pink-400": "#ec5062",
  "pink-500": "#d94456",
  "pink-600": "#ce273b",
  "pink-700": "#bc1528",
  "pink-800": "#841522",
  "pink-900": "#510e16",

  /* BLUE SCALE(ROOT) */
  "blue-50": "#e9f2ff",
  "blue-100": "#cce0ff",
  "blue-200": "#84b8ff",
  "blue-300": "#579dff",
  "blue-400": "#388bff",
  "blue-500": "#1d7afc",
  "blue-600": "#0c66e4",
  "blue-700": "#0055cc",
  "blue-800": "#0c418d",
  "blue-900": "#1c2b41",

  /* VIOLET SCALE(ROOT) */
  "violet-50": "#f3ebff",
  "violet-100": "#e8d6ff",
  "violet-200": "#d2afff",
  "violet-300": "#bb86ff",
  "violet-400": "#a55eff",
  "violet-500": "#8e37ff",
  "violet-600": "#722bcc",
  "violet-700": "#56219a",
  "violet-800": "#391666",
  "violet-900": "#1d0b34",

  /* TSKY SCALE(ROOT) */
  "tsky-50": "#f6f7fd",
  "tsky-100": "#edeefb",
  "tsky-200": "#dcdff7",
  "tsky-300": "#cacef4",
  "tsky-400": "#b9bef0",
  "tsky-500": "#a7aeec",
  "tsky-600": "#868bbd",
  "tsky-700": "#65698f",
  "tsky-800": "#43465e",
  "tsky-900": "#222330",

  /* TBLUE SCALE(ROOT) */
  "tblue-50": "#eae8f9",
  "tblue-100": "#d5d0f3",
  "tblue-200": "#ada3e8",
  "tblue-300": "#8374dd",
  "tblue-400": "#5b47d1",
  "tblue-500": "#3617ce",
  "tblue-600": "#28149e",
  "tblue-700": "#1e0f78",
  "tblue-800": "#140a4f",
  "tblue-900": "#0a0528",
};

const colors = {
  ...basic_colors,
  "background-tertiary-hover": basic_colors["tsky-300"],
  "background-primary-hover": basic_colors["grey-50"],
  "background-tertiary": basic_colors["tsky-200"],
  "background-overlay": basic_colors["grey-transparent-200"],
  "background-primary": basic_colors["grey-white"],
  "background-brand-inverse": basic_colors["tblue-500"],
  "background-secondary": basic_colors["tsky-50"],
  "background-secondary-hover": basic_colors["tsky-100"],
  "background-secondary-active": basic_colors["tsky-200"],
  "background-overlay-subtle": basic_colors["grey-transparent-500"],
  "background-quarternary": basic_colors["tsky-300"],
  "background-inverse" : basic_colors["grey-800"],
  "background-inverse-hover" : basic_colors["grey-600"],
  "background-inverse-active" : basic_colors["grey-400"],
  "button-brand1": basic_colors["tblue-500"],
  "button-brand1-hover": basic_colors["tblue-600"],
  "button-brand1-active": basic_colors["tblue-700"],
  "button-brand1-subtle": basic_colors["grey-white"],
  "button-brand1-subtle-hover": basic_colors["tblue-50"],
  "button-brand1-subtle-active": basic_colors["tblue-100"],
  "button-brand2": basic_colors["tblue-400"],
  "button-brand2-hover": basic_colors["tblue-500"],
  "button-brand2-active": basic_colors["tblue-600"],
  "button-brand2-subtle": basic_colors["tsky-100"],
  "button-brand2-subtle-hover": basic_colors["tsky-300"],
  "button-brand2-subtle-active": basic_colors["tsky-400"],
  "button-beige-subtle": "#00000000",
  "button-mute": basic_colors["tsky-50"],
  "button-mute-hover": basic_colors["tsky-100"],
  "button-mute-active": basic_colors["tsky-200"],
  "button-gray": basic_colors["grey-300"],
  "border-brand": basic_colors["tblue-500"],
  "border-active": basic_colors["tblue-500"],
  "border-secondary": basic_colors["grey-100"],
  "border-primary": basic_colors["grey-300"],
  "text-primary-inverse": basic_colors["grey-white"],
  "text-secondary": basic_colors["grey-700"],
  "text-secondary-inverse": basic_colors["grey-300"],
  "text-quaternary": basic_colors["grey-300"],
  "text-tertiary": basic_colors["grey-500"],
  "text-primary": basic_colors["grey-900"],
  "text-brand1": basic_colors["tblue-500"],
  "text-brand2": basic_colors["tsky-800"],
  "icon-primary-inverse": basic_colors["grey-white"],
  "icon-primary-disabled": basic_colors["grey-200"],
  "icon-brand-subtle": basic_colors["tblue-200"],
  "icon-tertiary": basic_colors["grey-400"],
  "icon-primary": basic_colors["grey-900"],
  "icon-brand1": basic_colors["tblue-500"],
  "icon-brand2": basic_colors["tsky-800"],
  "icon-disabled": basic_colors["grey-300"],
  "icon-secondary": basic_colors["grey-600"],
  "badge-brand1": basic_colors["tblue-50"],
  "badge-gre-subtle": basic_colors["grey-50"],
  "badge-brand2": basic_colors["tsky-700"],
  "badge-whitle": basic_colors["grey-white"],
  "support-error": basic_colors["red-500"],
  "support-shadow": basic_colors["grey-transparent-200"],
};

const spacing = {
  "spacing-none": "0px",
  "spacing-01": "2px",
  "spacing-02": "4px",
  "spacing-03": "6px",
  "spacing-04": "8px",
  "spacing-05": "10px",
  "spacing-06": "12px",
  "spacing-07": "16px",
  "spacing-08": "20px",
  "spacing-09": "24px",
  "spacing-10": "28px",
  "spacing-11": "32px",
  "spacing-12": "36px",
  "spacing-13": "40px",
  "spacing-14": "48px",
  "spacing-15": "56px",
  "spacing-16": "64px",
  "spacing-17": "80px",
  "spacing-18": "96px",
  "spacing-19": "120px",
  "spacing-20": "160px",
  "spacing-21": "200px",
  "spacing-22": "240px",
  "spacing-23": "280px",
};

const radius = {
  "radius-none": "0px",
  "radius-01": "4px",
  "radius-02": "8px",
  "radius-03": "12px",
  "radius-04": "16px",
  "radius-05": "20px",
  "radius-06": "24px",
  "radius-07": "32px",
  "radius-08": "40px",
  "radius-09": "48px",
  "radius-10": "56px",
  "radius-11": "64px",
  "radius-12": "72px",
  "radius-13": "80px",
  "radius-full": "9999px",
};

const typography = {
  "pre-heading-01": {
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "150%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-heading-02": {
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "150%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-heading-03": {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "150%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-heading-04": {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-01": {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-02": {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-03": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-04": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-05": {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-body-06": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-caption-01": {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-caption-02": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-caption-03": {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
  "pre-caption-04": {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "170.00000476837158%",
    letterSpacing: "-1%",
    textDecoration: "none",
    fontFamily: "Pretendard Variable",
  },
};

const shadow={
  "modal": "0px 0px 32px 0px rgba(0,0,0,0.2)"
}

const border={
  "border-01": "0.5px",
  "border-02": "1px",
  "border-03": "2px",
  "border-04": "3px",
  "border-05": "4px",
}

const theme = {
  colors,
  spacing,
  radius,
  typography,
  shadow,
  border
};

export default theme;

